export enum Language {
  EN = 'en',
  RU = 'ru',
}

export enum Blockchain {
  ETHEREUM = 'ETHEREUM',
  BSC = 'BSC',
  POLYGON = 'POLYGON',
  ARBITRUM = 'ARBITRUM',
  AVALANCHE = 'AVALANCHE',
  SOLANA = 'SOLANA',
  BASE = 'BASE',
  TON = 'TON',
  TRON = 'TRON',
  SUI = 'SUI',
}

export enum Dex {
  UNISWAP = 'UNISWAP',
  SUSHISWAP = 'SUSHISWAP',
  ETHERVISTA = 'ETHERVISTA',
  PANCAKESWAP = 'PANCAKESWAP',
  RAYDIUM = 'RAYDIUM',
  METEORA = 'METEORA',
  MOONSHOT = 'MOONSHOT',
  PUMPFUN = 'PUMPFUN',
  STONFI = 'STONFI',
  DEDUST = 'DEDUST',
  SUNSWAP = 'SUNSWAP',
  SUNPUMP = 'SUNPUMP',
  CETUS = 'CETUS',
}

export enum Bridge {
  ORBITER = 'ORBITER',
}

export enum PairType {
  UNI_V2 = 'UNI_V2', // AMM: x*y=k
  UNI_V3 = 'UNI_V3', // CLMM: ticks
  RAY_V4 = 'RAY_V4',
  RAY_CP = 'RAY_CP',
  METEORA_DLMM = 'METEORA_DLMM',
  METEORA_DYN = 'METEORA_DYN',
  MOONSHOT = 'MOONSHOT',
  PUMPFUN = 'PUMPFUN',
  STONFI_V1 = 'STONFI_V1',
  STONFI_V2 = 'STONFI_V2',
  DEDUST_V2 = 'DEDUST_V2',
}

export enum BaseToken {
  // Native
  ETH = 'ETH',
  BNB = 'BNB',
  MATIC = 'MATIC',
  SOL = 'SOL',
  AVAX = 'AVAX',
  // Stables
  USDT = 'USDT',
  USDC = 'USDC',
  DAI = 'DAI',
  TRX = 'TRX',
  SUI = 'SUI',
}

export enum TradeType {
  BUY = 'BUY',
  SELL = 'SELL',
}

export enum OrderTrigger {
  PRICE_IN_USD = 'PRICE_IN_USD',
  PRICE_IN_BASE_TOKENS = 'PRICE_IN_BASE_TOKENS',
  MCAP = 'MCAP',
}

export enum OrderStatus {
  OPEN = 'OPEN',
  EXECUTING = 'EXECUTING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED',
}

export enum OrderTransactionType {
  BUY = 'BUY',
  SELL = 'SELL',
  APPROVE = 'APPROVE',
}

export enum OrderTransactionStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  LOST = 'LOST',
}

export enum WalletType {
  EVM = 'EVM',
  SOL = 'SOL',
  TON = 'TON',
  TRON = 'TRON',
  SUI = 'SUI',
  BTC = 'BTC',
}

export enum BaseChainToken {
  BSC = 'BNB',
  BASE = 'ETH',
  ETHEREUM = 'ETH',
  TON = 'TON',
  TRON = 'TRX',
  SOLANA = 'SOL',
  SUI = 'SUI',
}
