import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FormattedNumber from '@components/common/FormattedNumber';
import BigNumber from 'bignumber.js';
import { chainLogo, chainToken, chainTxExplorer } from '@helpers/chains';
import { getEllipsisTxt } from '@helpers/formatters';
import swapArrow from '@assets/images/send-arrow.svg';
import gasIcon from '@assets/icons/gas.svg';
import { ChainId, PageRoutes, zeroAddress } from '../../constants';
import { toWei } from '@helpers/numbers';
import Preloader from '@components/common/Preloader';
import BadgeIcon from '@components/common/BadgeIcon';
import { TransactionDetails } from '../../types';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import TokenLogo from '@components/common/TokenLogo';
import useResponseHandler from '@hooks/useResponseHandler';
import IconWithLoading from '@components/common/IconWithLoading';
import usePopup from '@hooks/usePopup';
import { Blockchain } from '../../types/enums';
import { useTranslation } from 'react-i18next';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import { PortfolioToken } from 'types/portfolio.model';
import { hasOwnProperty } from '@helpers/object';

export interface SendConfirmProps {
  address?: string;
  amount?: string;
  network?: ChainId;
  assetSend?: PortfolioToken;
  assetReceive?: PortfolioToken | null;
  slippage?: string;
}

const SendConfirm = observer(
  ({
     address,
     amount,
     network,
     assetSend: asset,
     assetReceive,
     slippage = '5',
   }: SendConfirmProps) => {
    const { t } = useTranslation();
    const { accountStore, blockchainStore, walletStore } = useStores();
    const { address: accountAddress, currentWallet, isEvm } = accountStore;
    const { assets } = walletStore;
    const navigate = useNavigate();
    const [w] = useAmplitude();
    const location = useLocation();
    const { state } = location;
    const [tx, setTx] = useState<TransactionDetails | null>(null);
    const [txHash, setTxHash] = useState<string>('');
    const [isSending, setIsSending] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const handleResponse = useResponseHandler();
    const showPopup = usePopup();

    const networkTokens = useMemo(() => {
      return assets;
    }, [assets, network]);

    const nativeToken = useMemo(() => {
      const networkToken = chainToken(network || ChainId.ETHER);
      return networkTokens.find(
        (asset) => asset.symbol.toLowerCase() === networkToken.toLowerCase(),
      );
    }, [networkTokens, network]);

    const amountReceive = new BigNumber(amount || 0)
      .times(asset?.price || 0)
      .div(assetReceive?.price || 1)
      .toNumber();

    const handleSubmit = () => {
      if (
        !assetReceive &&
        (!network || !address || !amount || !asset || (isEvm && !state.gas))
      ) {
        return;
      }
      if (
        assetReceive &&
        (!network || !amount || !asset || (isEvm && !state.gas))
      ) {
        return;
      }

      setIsSending(true);
      if (!assetReceive) {
        walletStore.addRecentWallet(address!);
        w(() => null, AmplitudeEvent.SEND_CONFIRMATION_CLICKED, {
          token: asset || nativeToken,
          amount,
        });
        blockchainStore
          .sendToken({
            walletId: currentWallet!.id,
            blockchain: network as unknown as Blockchain,
            tokenAddress:
              !asset || nativeToken?.address === asset.address
                ? undefined
                : asset.address,
            toAddress: address!,
            value: amount || '0',
            memo: state.memo,
            // network: network || ChainId.ETHER,
            // receiverAddress: address!,
            // tokenAmount: toWei(amount || '0', asset.decimals),
            // tokenAddress: asset.contractAddress || zeroAddress,
            // walletAddress: accountAddress,
            // gasPrice: new BigNumber(gas.gwei || '0').times(10 ** 9).toString(),
            // gasLimit: Math.ceil(new BigNumber(gas!.optimalGasLimit).toNumber()).toString(),
          })
          .then((response) => {
            if (response?.transactionHash) {
              setTxHash(response.transactionHash);
              setTx({
                blockHash: '',
                from: '',
                blockNumber: 0,
                chainId: 0,
                gasLimit: 0,
                hash: '',
                gasPrice: new BigNumber(0).toNumber(),
                isMined: true,
                status: 1,
                value: new BigNumber(0).toNumber(),
                to: '',
              });
              setIsProcessing(false);
              setIsSending(false);
            } else {
              setIsSending(false);
              handleResponse(hasOwnProperty(response, 'response') ? response.response : response);
            }
          })
          .catch((e) => {
            setIsSending(false);
            console.error(e);
            handleResponse(e);
          });
      } else {
        blockchainStore
          .swapTokens({
            network: network || ChainId.ETHER,
            fromTokenAmount: toWei(amount || '0',/* asset?.decimals ||*/ 18),
            fromTokenAddress: asset?.address || zeroAddress,
            toTokenAddress: assetReceive.address || zeroAddress,
            walletAddress: accountAddress,
            gasPrice: new BigNumber(state.gas?.gas || '0')
              .times(10 ** 9)
              .toString(),
            gasLimit: Math.ceil(
              new BigNumber(state.gas?.gas || 0).toNumber(),
            ).toString(),
            slippagePercentage: new BigNumber(slippage || '5')
              .div(100)
              .toString(),
          })
          .then((response) => {
            if (response?.transactionHash) {
              setTxHash(response.transactionHash);
              setTx({
                blockHash: '',
                from: '',
                blockNumber: 0,
                chainId: 0,
                gasLimit: 0,
                hash: '',
                gasPrice: new BigNumber(0).toNumber(),
                isMined: true,
                status: 1,
                value: new BigNumber(0).toNumber(),
                to: '',
              });
              setIsProcessing(false);
              setIsSending(false);
            } else {
              setIsSending(false);
              handleResponse(response);
            }
          })
          .catch((e) => {
            setIsSending(false);
            handleResponse(e);
          });
      }
    };

    const fetchTx = () => {
      blockchainStore
        .getBlockchainTransaction(txHash, network || ChainId.ETHER)
        .then((response) => {
          if (response && response.hash) {
            setIsSending(false);
            setTx(response);
            setIsProcessing(true);
            walletStore.loadData();
          } else {
            if (
              response.data.error &&
              response.data.error === t('sending.transaction-not-found')
            ) {
              // that's fine, trust me
              console.log(t('sending.transaction-not-found-but-we-know'));
            } else {
              console.log({ response });
              setIsSending(false);
              handleResponse(response);
            }
          }
        })
        .catch((e) => {
          console.error({ e });
          setIsSending(false);
          handleResponse(e);
        });
    };

    // useInterval(() => {
    //   fetchTx();
    // }, txHash && (!tx || !tx.isMined || tx.status === null) ? 3000 : null);

    // useEffect(() => {
    //   if (tx?.isMined) {
    //     setIsProcessing(false);
    //   }
    // }, [tx]);

    // useInterval(() => {
    //   setTimer(timer - 1);
    // }, timer > 0 ? 1000 : null);

    const feeAmount = useMemo(() => {
      return state.gas
        ? new BigNumber(state.gas.gas || 0)
          .times(state.gas.weiGasPrice || 0)
          .div(10 ** /* nativeToken?.decimals || */ 18)
          .toString()
        : null;
    }, [state.gas, nativeToken]);

    useEffect(() => {
      if (new BigNumber(amount || 0).isLessThanOrEqualTo(0)) {
        navigate(PageRoutes.WALLET);
      }
    }, [amount]);

    if (isProcessing || isSending) {
      return (
        <div className="py-4">
          <Preloader
            className="d-flex flex-column"
            iconSize={65}
            textClass="mt-3"
            text={`${assetReceive ? t('sending.waiting-confirmations') : t('sending.sending')}`}
            inline
          />
        </div>
      );
    }

    if (!isProcessing && tx) {
      return (
        <div className="full-page d-flex flex-column align-items-center justify-content-center tx-center ht-100p">
          <BadgeIcon badgeSize={110} className="tx-64 mb-3">
            {tx.status === 1 ? '👍' : '🚫'}
          </BadgeIcon>
          {tx.status === 1 && (
            <div className="tx-semibold tx-28">
              <div>
                {assetReceive
                  ? t('sending.transaction-successful')
                  : t('sending.transfer-successful')}
              </div>
              <div className={`${assetReceive ? 'tx-success' : ''}`}>
                {assetReceive
                  ? amountReceive
                  : new BigNumber(amount || 0).times(-1).toNumber()}
              </div>
              <div className="d-flex flex-row justify-content-center align-items-center">
                <TokenLogo
                  logo={(assetReceive || asset)?.image}
                  address={(assetReceive || asset)?.address || zeroAddress}
                  name={(assetReceive || asset)?.name || 'N/A'}
                  chain={network || ChainId.ETHER}
                  size={30}
                  hideChain
                  containerClassName="me-2"
                />
                {(assetReceive || asset)?.symbol}
              </div>
            </div>
          )}

          {tx.status === 0 && (
            <div>
              <div className="tx-semibold tx-28">
                {t('sending.oops-transfer-failed')}
              </div>
            </div>
          )}

          <button
            className="btn btn-primary-10 wd-230 mt-4"
            onClick={() => navigate(PageRoutes.WALLET)}
          >
            {t('common.done')}
          </button>

          {!!network && (
            <div className="tx-13 tx-muted mt-4">
              {t('bridge.check-tx-on')}
              <a
                href={chainTxExplorer(network, txHash)}
                target="_blank"
                rel="noreferrer"
                className="tx-white ms-1"
              >
                {
                  chainTxExplorer(network, txHash)
                    .split('https://')[1]
                    .split('.')[0]
                }
              </a>
            </div>
          )}
        </div>
      );
    }

    if (!asset || !network) {
      return (
        <div className="py-4">
          <div className="card tx-center mb-4">
            {t('bridge.error-asset-not-found')}
          </div>

          <button
            className="btn btn-primary-10 wd-100p"
            onClick={() => navigate(-1)}
          >
            {t('common.back')}
          </button>
        </div>
      );
    }

    return (
      <div className="py-4">
        {/*<div className="card flex-row justify-content-between mb-4">*/}
        {/*  <span>New Quotes in:</span>*/}
        {/*  <span className="tx-danger">0:{timer < 10 ? `0${timer}` : timer}</span>*/}
        {/*</div>*/}

        <div className="card align-items-center py-4">
          <TokenLogo
            logo={asset.image}
            address={asset.address}
            name={asset.name}
            chain={network || ChainId.ETHER}
            hideChain
            size={35}
          />
          <div className="tx-semibold mt-2 tx-center">
            {amount} {asset.symbol}
          </div>
          <FormattedNumber
            value={new BigNumber(amount || 0).times(asset.price || 1).toNumber()}
            suffix="$"
            className="tx-13 tx-muted"
            decimals={4}
            subZeros
          />
        </div>

        <div className="swap-separator between-cards">
          <img src={swapArrow} alt="Swap" className="wd-45 ht-45" />
        </div>

        {!assetReceive && (
          <div className="card align-items-center py-4 mb-4">
            <img
              src={chainLogo(network || 'ethereum')}
              alt={network || 'ethereum'}
              className="wd-35 ht-35 mn-wd-35 mb-2"
            />
            <div className="tx-semibold tx-12 tx-center mx-wd-100p">
              {address}
            </div>
            <div className="tx-12 tx-muted">
              {getEllipsisTxt(address || '', 5, '0x')}
            </div>
          </div>
        )}

        {assetReceive && (
          <div className="card align-items-center py-4 mb-4">
            <TokenLogo
              logo={assetReceive.image}
              address={assetReceive.address}
              name={assetReceive.name}
              size={35}
            />
            <div className="tx-semibold mt-2">
              {amountReceive} {assetReceive.symbol}
            </div>
            <FormattedNumber
              value={new BigNumber(amountReceive || 0)
                .times(assetReceive.price || 0)
                .toNumber()}
              suffix="$"
              className="tx-13 tx-muted"
              decimals={4}
              subZeros
            />
          </div>
        )}

        {assetReceive && (
          <div className="d-flex justify-content-center align-items-center mb-4 tx-semibold">
            <span>1 {asset.symbol}</span>
            <span className="mx-1">=</span>
            <FormattedNumber
              value={new BigNumber(asset.price || 0)
                .div(assetReceive.price || 0)
                .toNumber()}
              postfix={assetReceive.symbol}
              decimals={4}
              subZeros
            />
          </div>
        )}
        {state?.memo && (
          <div className="card mb-4 flex-row justify-content-end gap-3">
            <div className="tx-bold me-auto">{t('wallet.tag')}:</div>
            <div className="tx-muted text-break">{state.memo}</div>
          </div>
        )}

        <div className="card">
          <div className="d-flex justify-content-end align-items-center tx-semibold">
            <div className="ms-0 me-auto">{t('common.total')}:</div>
            <div className="word-break lh-1 ps-2">
              {amount} {asset.symbol}
            </div>
            <FormattedNumber
              value={new BigNumber(amount || 0).times(asset.price || 0).toNumber()}
              suffix="($"
              postfix=")"
              className="tx-muted ms-1"
              decimals={4}
              subZeros
            />
          </div>

          {isEvm && (
            <div className="d-flex justify-content-between align-items-center tx-13 tx-muted my-2">
              <img src={gasIcon} alt="Estimated Fee" />
              <div className="ms-2 me-auto">{t('sending.estimated-fee')}</div>
              <FormattedNumber
                value={feeAmount}
                decimals={6}
                postfix={nativeToken?.symbol}
                className="me-1 tx-white"
                subZeros
              />
              <FormattedNumber
                value={
                  feeAmount && state.gas
                    ? new BigNumber(feeAmount)
                      .times(state.gas.nativeTokenUsdPrice || 0)
                      .toNumber()
                    : null
                }
                suffix="($"
                postfix=")"
                subZeros
                decimals={4}
              />
            </div>
          )}
        </div>

        <button
          className="btn btn-primary-10 wd-100p mt-4"
          onClick={handleSubmit}
          disabled={isSending}
        >
          <IconWithLoading isLoading={isSending} />
          {t('common.confirm')}
        </button>

        <button
          className="btn btn-transparent wd-100p mt-4"
          onClick={w(() => {
            showPopup({
              title: t('sending.cancel-transaction'),
              message: assetReceive
                ? t('sending.cancel-exchange-confirmation') + '?'
                : t('sending.cancel-transfer-confirmation') + '?',
              buttons: [
                {
                  text: t('common.yes'),
                  type: 'danger',
                  id: 'cancel-send',
                },
                {
                  text: t('common.close'),
                  id: 'cancel',
                },
              ],
            }).then((result) => {
              if (result === 'cancel-send') {
                navigate(-1);
              }
            });
          }, AmplitudeEvent.SEND_CANCEL_CLICKED)}
        >
          {t('common.cancel')}
        </button>
      </div>
    );
  },
);

export default SendConfirm;
