/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import AmountPercentInput from '@pages/LimitOrders/components/AmountPercentInput';
import FormattedNumber from '@components/common/FormattedNumber';
import BigNumber from 'bignumber.js';
import { ResearchPairResponse } from '../../../types/research/research-pair.response';
import { OrderTrigger } from '../../../types/enums';
import { smallerThanOrEqual } from '@helpers/bignumber';
import { useTranslation } from 'react-i18next';
import { useStores } from '@hooks/useStores';
import { observer } from 'mobx-react-lite';

interface TradeOrderDescriptionProps {
  type: OrderTrigger | null;
  isBuy?: boolean;
  token: ResearchPairResponse;
  isDown: boolean;
  diff: number;
}

const types = [
  OrderTrigger.PRICE_IN_USD,
  OrderTrigger.PRICE_IN_BASE_TOKENS,
  OrderTrigger.MCAP,
];

const TradeOrderIntlDescription = ({
  props,
}: {
  props: TradeOrderDescriptionProps;
}) => {
  const { type, isBuy, token, isDown, diff } = props;
  const {
    i18n: { language },
  } = useTranslation();
  const l = (language: string) => {
    if ('ru-RU'.includes(language)) return 'ru-RU';
    if ('en-EN'.includes(language)) return 'en-EN';
  };

  const desc: { [locale: string]: JSX.Element } = {
    'en-EN': (
      <>
        Will
        <span className={`${isBuy ? 'tx-success' : 'tx-danger'} mx-1`}>
          {isBuy ? 'buy' : 'sell'}
        </span>
        {token.quoteToken.symbol} after its
        <span className="mx-1">
          {type === OrderTrigger.MCAP ? 'market capitalization' : 'price'}
        </span>
        goes {isDown ? 'down' : 'up'}
        <span className={`${!isDown ? 'tx-success' : 'tx-danger'} ms-1`}>
          {new BigNumber(diff).toFixed(2, 1)}%
        </span>
      </>
    ),
    'ru-RU': (
      <>
        <span className={`${isBuy ? 'tx-success' : 'tx-danger'} me-1`}>
          {isBuy ? 'Купить' : 'Продать'}
        </span>
        {token.quoteToken.symbol}, когда его
        <span className="mx-1">
          {type === OrderTrigger.MCAP ? 'рыночная капитализация' : 'цена'}
        </span>
        {isDown ? 'упадет' : 'вырастет'} на
        <span className={`${!isDown ? 'tx-success' : 'tx-danger'} ms-1`}>
          {new BigNumber(diff).toFixed(2, 1)}%
        </span>
      </>
    ),
  };

  return (
    <div className="tx-muted tx-13 mt-3">{desc[l(language) ?? 'en-EN']}</div>
  );
};

const TradeOrderTrigger = () => {
  const { t } = useTranslation();
  const { tokenStore, settingsStore, tokenTrade } = useStores();
  const { percents, triggers } = settingsStore;
  const { tokenDetails: token } = tokenStore;
  const {
    isBuy,
    isStopLoss,
    order,
    triggerAmount: amount,
    triggerPercent: amountPercent,
    triggerType: type,
    diff,
  } = tokenTrade;

  const buttons = [`${t('common.price')} $`, `${t('common.price')} %symbol`];

  const { price, priceBase, mCap } = {
    price: token!.usdPrice,
    priceBase: token!.priceInBaseToken,
    mCap: token!.mcap,
  };

  const totalSupply = useMemo(() => {
    return new BigNumber(mCap).dividedBy(price).toNumber();
  }, [mCap, price]);

  const currentData = useMemo(() => {
    let label = t('order-trigger.current-price');
    let value: number | string =
      type === OrderTrigger.PRICE_IN_USD ? price : priceBase;
    if (type === OrderTrigger.MCAP) {
      label = t('order-trigger.current-mcap');
      value = mCap;
    }
    return (
      token && {
        label: label.replace('%symbol', token.baseToken.symbol),
        value,
      }
    );
  }, [type, price, priceBase, mCap, token?.baseToken.symbol]);

  const isDown = useMemo(() => {
    return !!currentData && smallerThanOrEqual(amount || 0, currentData.value);
  }, [amount, currentData?.value]);

  const predefined = useMemo(() => {
    return isBuy || isStopLoss ? percents : triggers;
  }, [isBuy, isStopLoss]);

  const usdPrice = useMemo(() => {
    if (type === OrderTrigger.PRICE_IN_USD) return amount || 0;
    return new BigNumber(amount || 0).dividedBy(priceBase).toNumber();
  }, [amount, priceBase, type]);

  const mCapWillBe = useMemo(() => {
    return new BigNumber(totalSupply).multipliedBy(usdPrice).toNumber();
  }, [totalSupply, usdPrice]);

  return (
    token && (
      <>
        <div className="tx-17 tx-semibold mt-4 mb-2">
          {t('order-trigger.trigger')}
        </div>

        <div className="card">
          <div className="d-flex flex-row gap-2 pb-3 border-bottom border-semi-transparent">
            {buttons.map((b, index) => (
              <button
                key={b}
                className={`btn py-2 px-1 justify-content-center align-items-center wd-100p tx-13 ${
                  type === types[index] ? 'btn-primary' : 'btn-semi-10'
                }`}
                onClick={() => tokenTrade.setTriggerType(types[index])}
                disabled={!!order}
              >
                {b.replace('%symbol', token.baseToken.symbol)}
              </button>
            ))}
          </div>

          {currentData && (
            <AmountPercentInput
              amount={amount}
              amountChange={(v) => tokenTrade.setTriggerAmount(v)}
              amountPercent={amountPercent}
              amountPercentChange={(v) => tokenTrade.setTriggerPercent(v)}
              symbol={
                type === OrderTrigger.PRICE_IN_BASE_TOKENS
                  ? token.baseToken.symbol
                  : '$'
              }
              predefined={predefined.map((a: number) =>
                !isBuy && !isStopLoss ? a : a * -1,
              )}
              targetValue={currentData.value}
              label={currentData.label}
              calcFromTotal={isBuy || isStopLoss}
              isInverted={isBuy || isStopLoss}
              invertPredefined={isBuy || isStopLoss}
              isPercent={tokenTrade.triggerPercentMode}
              switchMode={() => tokenTrade.switchTriggerMode()}
              isInvalid={new BigNumber(amountPercent || 0).isLessThan(0)}
            />
          )}

          <TradeOrderIntlDescription
            props={{ type, isBuy, token, isDown, diff }}
          />

          <div className="tx-muted tx-13">
            {t('order-trigger.market-cap-will-be')}
            <FormattedNumber
              value={mCapWillBe}
              decimals={0}
              className={`ms-1 ${!isDown ? 'tx-success' : 'tx-danger'}`}
              postfix="$"
            />
          </div>
        </div>
      </>
    )
  );
};

export default observer(TradeOrderTrigger);
