import BigNumber from 'bignumber.js';
import { TokenBalanceResponse } from 'types/order/token-balance.response';
import { PriceUpdateResponse } from 'types/socket/price-update.types';
import {
  calcAdjustedPercent,
  calcPnlPercents,
  calcPnlUsd,
} from '@helpers/calculations';
import { ResearchPairResponse } from 'types/research/research-pair.response';
import { OrdersSummaryResponse } from 'types/order/orders-summary.response';

export const getUpdatedTokenBalance = (
  tokenBalanceObj: TokenBalanceResponse,
  priceUpdateObj: PriceUpdateResponse,
): TokenBalanceResponse => {
  const { tokenBalance, totalUsdBuyValue, totalUsdSellValue } = tokenBalanceObj;
  const { tokenPriceInUsd } = priceUpdateObj;

  const [
    bnTokenBalance,
    bnTotalUsdBuyValue,
    bnTotalUsdSellValue,
    bnTokenPriceInUsd,
  ] = [tokenBalance, totalUsdBuyValue, totalUsdSellValue, tokenPriceInUsd].map(
    (v) => new BigNumber(v ?? NaN),
  );

  const updTokenBalance = bnTokenBalance.times(bnTokenPriceInUsd);

  const updPnlPercents = new BigNumber(
    !totalUsdBuyValue || totalUsdBuyValue === 0
      ? new BigNumber(0)
      : calcPnlPercents(
          bnTokenBalance,
          bnTokenPriceInUsd,
          bnTotalUsdSellValue,
          bnTotalUsdBuyValue,
        ).decimalPlaces(1),
  );

  const updPnlUsd =
    !totalUsdBuyValue || totalUsdBuyValue === 0
      ? new BigNumber(0)
      : calcPnlUsd(
          bnTokenBalance,
          bnTokenPriceInUsd,
          bnTotalUsdSellValue,
          bnTotalUsdBuyValue,
        ).decimalPlaces(2);

  return {
    ...tokenBalanceObj,
    tokenBalance,
    totalUsdBuyValue,
    totalUsdSellValue,
    tokenBalanceInUsd: updTokenBalance.valueOf(),
    pnlUsd: updPnlUsd.valueOf(),
    pnlPercent: updPnlPercents.valueOf(),
  };
};

export const getUpdatedTokenDetails = (
  tokenDetails: ResearchPairResponse,
  priceUpdate: PriceUpdateResponse,
) => {
  const priceChangePercent24h = calcAdjustedPercent(
    tokenDetails.priceInBaseToken,
    tokenDetails.priceChangePercent24h,
    priceUpdate.tokenPriceInBaseTokens,
  );
  const mcap = new BigNumber(priceUpdate.tokenMcap).toNumber();
  const usdPrice = priceUpdate.tokenPriceInUsd;
  const priceInBaseToken = priceUpdate.tokenPriceInBaseTokens;

  return {
    ...tokenDetails,
    priceChangePercent24h,
    mcap,
    usdPrice,
    priceInBaseToken,
  };
};

export const getUpdatedSummary = (
  summaryItem: OrdersSummaryResponse,
  priceUpdate: PriceUpdateResponse,
) => {
  const [bnTokenBalance, bnTokenBalanceInUsd, bnTokenPriceInUsd] = [
    summaryItem.tokenBalance,
    summaryItem.tokenBalanceInUsd,
    priceUpdate.tokenPriceInUsd,
  ].map((v) => new BigNumber(v ?? NaN));

  if (bnTokenBalance.isZero()) return summaryItem;

  const updTokenBalanceInUsd = bnTokenBalance
    .times(bnTokenPriceInUsd)
    .decimalPlaces(2);

  const pnlPercent = calcPnlPercents(
    bnTokenBalance,
    bnTokenPriceInUsd,
    summaryItem.totalUsdSellValue,
    summaryItem.totalUsdBuyValue,
  ).toString();

  return {
    ...summaryItem,
    tokenBalanceInUsd: updTokenBalanceInUsd.valueOf(),
    pnlPercent,
  };
};
