import React, {useEffect, useMemo, useState} from "react";
import {Modal} from "react-bootstrap";
import {RANK_RULES, ReferralLevelType} from "@pages/Referral/constants";
import ReferralBadge from "@pages/Referral/components/ReferralBadge";
import Progress from "@components/common/Progress";
import FormattedNumber from "@components/common/FormattedNumber";
import {formatNumber, numberWithCommas} from "@helpers/numbers";
import { useTranslation } from "react-i18next";

interface ReferralLevelModalProps {
  type: ReferralLevelType | null;
  valueNow: number;
  onClose: () => void;
}

const ReferralLevelModal = ({ type, valueNow, onClose }: ReferralLevelModalProps) => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);
  const [lastType, setLastType] = useState<ReferralLevelType>();

  const level = useMemo(() => {
    return RANK_RULES.find(level => level.rank === lastType) || RANK_RULES[0];
  }, [lastType]);

  const progressBg = useMemo(() => {
    switch (lastType) {
      case ReferralLevelType.BRONZE:
        return 'beige';
      case ReferralLevelType.SILVER:
        return 'silver';
      case ReferralLevelType.GOLD:
        return 'gold';
      case ReferralLevelType.PLATINUM:
        return 'white';
      case ReferralLevelType.DIAMOND:
        return 'diamond-gradient';
      case ReferralLevelType.MASTER:
        return 'master-gradient';
      case ReferralLevelType.ULTRA:
        return 'blue';
      default:
        return 'beige';
    }
  }, [lastType]);

  useEffect(() => {
    if (type && lastType !== type) {
      setLastType(type);
    }
    setShow(!!type);
  }, [lastType, type]);

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{level.label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card mb-4 bg-semi-transparent-10">
          <div className="d-flex flex-row justify-content-start align-items-center">
            <div className="wd-65 ht-65 mn-wd-65 mx-wd-65">
              <ReferralBadge type={level.rank} />
            </div>
            <div className="d-flex flex-column ms-3">
              <div className={`tx-17 tx-semibold ${level.rank === 6 ? 'tx-white' : valueNow > (level.min30dUsdVolume || 0) ? 'tx-success' : ''}`}>{level.label}</div>
              {level.rank !== 6 && (
                <div className="tx-13">{t('referral.turnover-usd')}{numberWithCommas((level.min30dUsdVolume || 0), false, 4, false, 0)}</div>
              )}
            </div>
          </div>
        </div>

        <div className="card mb-4 bg-semi-transparent-10">
          <div className="d-flex flex-column wd-100p">
            <div className="tx-17 tx-semibold mb-3">{t('referral.referral-bonus')}</div>

            <div className="d-flex justify-content-between align-items-center tx-15">
              <div className="tx-muted">{t('referral.invited-by-you')}:</div>
              <div>{level.refRewardPercents[0]}%</div>
            </div>

            <div className="d-flex justify-content-between align-items-center tx-15">
              <div className="tx-muted">{t('referral.invited-by-your-friends')}:</div>
              <div>{level.refRewardPercents[1]}%</div>
            </div>

            <div className="d-flex justify-content-between align-items-center tx-15">
              <div className="tx-muted">{t('referral.total-bonus')}:</div>
              <div>{level.refRewardPercents[0] + level.refRewardPercents[1]}%</div>
            </div>
          </div>
        </div>

        <div className={`${level.min30dUsdVolume ? 'card' : 'd-none'} mb-4 bg-semi-transparent-10`}>
          <div className="tx-17 tx-semibold">{t('referral.turnover-usd')}{numberWithCommas(level.min30dUsdVolume || 0, false, 0, false, 0)}</div>
          <Progress
            value={!level.min30dUsdVolume || valueNow > level.min30dUsdVolume ? 100 : valueNow}
            max={level.min30dUsdVolume || 100}
            forceColor={progressBg}
            className="mt-2 wd-100p"
          />
          <div className="tx-12 tx-muted d-flex align-items-center">
            <FormattedNumber
              value={valueNow}
              suffix="$"
              floor
              className="me-1"
              useFormat
            />
            {t('common.of')}
            <FormattedNumber
              value={level.min30dUsdVolume}
              suffix="$"
              floor
              className="mx-1"
              useFormat
            />
            {t('referral.to')}
            <button className="btn btn-transparent p-0 tx-right tx-capitalize tx-12 tx-muted text-decoration-underline ms-1">
              {level.label}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ReferralLevelModal;
