import axios, { AxiosError, AxiosResponse, GenericAbortSignal } from 'axios';
import { ENV } from '../constants';
// import {applyAuthTokenInterceptor} from "axios-jwt";

export const axiosInstance = axios.create({
  baseURL: `${ENV.API_URL}/api/v1`,
  headers: {
    'ngrok-skip-browser-warning': '69420',
  }
  // headers: {
  //   'Authorization': `Bearer ${localStorage.getItem('token') || ''}`,
  // },
});

export interface ApiError {
  error?: string;
  serviceName?: string;
}

export interface ResponseData<T> extends ApiError {
  data?: T;
  status: boolean;
}

export interface StatusResponse<T> {
  status: number;
  data?: ResponseData<T>;
}

export interface Paged<T> {
  content: T;
  totalPages: number;
}

// applyAuthTokenInterceptor(axiosInstance, {
//   requestRefresh: () => {
//     return new Promise((resolve, reject) => {
//       resolve(localStorage.getItem('token') || '');
//     });
//   },  // async function that takes a refreshToken and returns a promise the resolves in a fresh accessToken
//   header: "Authorization",  // header name
//   headerPrefix: "Bearer ",  // header value prefix
// });

export enum ApiMethod {
  POST = 'post',
  GET = 'get',
  DELETE = 'delete',
  PUT = 'put',
  PATCH = 'patch',
}

export interface ApiProps {
  method: `${ApiMethod}`;
  path: string;
  data?: any;
  v2?: boolean;
  signal?: GenericAbortSignal;
}

export enum ApiServer {
  DEFAULT = 'default',
  DEX = 'dex',
}

export default function api<T>(
  { method, path, data }: ApiProps,
  server = ApiServer.DEFAULT,
): Promise<T> {
  let baseURL = ENV.API_URL;
  switch (server) {
    case ApiServer.DEX:
      baseURL = ENV.DEX_API_URL;
      break;
    default:
      baseURL = ENV.API_URL;
      break;
  }
  baseURL += '/api/v1';
  const sign = path.includes('?') ? '&' : '?';
  const url =
    data && method === 'get'
      ? `${path}${sign}${new URLSearchParams(data)}`
      : path;
  return new Promise((resolve, reject) => {
    axiosInstance<T>({
      method,
      url,
      data,
      baseURL,
    })
      .then((response) => {
        if (
          response.data !== undefined &&
          response.status >= 200 &&
          response.status < 300
        ) {
          // @ts-ignore
          resolve<T>(response.data);
        } else {
          reject(response);
        }
      })
      .catch((response) => {
        reject(response);
      });
  });
}

export function apiRaw<T>({
  method,
  path,
  data,
  signal,
}: ApiProps): Promise<AxiosResponse<T>> {
  const url =
    method === 'get'
      ? `${path}${data ? '?' : ''}${new URLSearchParams(data)}`
      : path;
  return new Promise((resolve, reject) => {
    axiosInstance<T>({
      method,
      url,
      data,
      signal,
    })
      .then((response) => {
        if (response) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((response) => {
        reject(response);
      });
  });
}
