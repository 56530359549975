import { action, computed, makeAutoObservable, observable, reaction, runInAction } from 'mobx';
import api, { Paged } from '@helpers/api';
import { ResearchPairResponse } from 'types/research/research-pair.response';
import { TokenBalanceResponse } from 'types/order/token-balance.response';
import { GasResponse } from 'types/common.types';
import { SocketStore } from './socket-store';
import { AccountStore } from './account-store';
import { OrdersStore } from './orders-store';
import { AxiosError } from 'axios';
import { ChainId, PageRoutes } from '../constants';
import { BaseToken, Blockchain, Dex, PairType, WalletType } from 'types/enums';
import { OrderResponse } from 'types/order/order.response';
import { OrderStatus, OrderTrigger, OrderType } from 'types/order/order.enum';
import BigNumber from 'bignumber.js';
import { BlockchainStore } from '@stores/blockchain-store';
import { PriceUpdateResponse } from 'types/socket/price-update.types';
import { getUpdatedTokenBalance, getUpdatedTokenDetails } from '@helpers/priceUpdate';
import { FlipModeType, PortfolioToken, SolanaFlipBalance, UserSettings } from 'types';
import { OrdersSummaryResponse } from 'types/order/orders-summary.response';
import { TokenError, TokenEvents } from '@stores/token-store';
import { CreateOrderRequest } from '../types/order/create-order.request';
import { defaultOrderSettings, SettingsStore } from '@stores/settings-store';
import { allOrderFilters } from '@pages/LimitOrders/components/LimitOrdersHistory';
import { CommandResultResponse } from '../types/command-result.response';
import { FlipOrderParams, FlipSettings } from 'types/flip/flip-settings.request';
import { defaultFlipAmounts, defaultFlipSettings } from 'constants/default-settings';
import { debouncedReaction, effect } from '@helpers/mobx';
import clone from '@helpers/clone';
import { SolPriorityMode } from 'types/user/settings.types';
import { getLocalStorageObject, removeLocalStorageObject, updateLocalStorageObject } from '@helpers/localStorage';

export interface FlipRouteParams {
  address?: string;

  [key: string]: string | undefined;
}

const DEBOUNCE_TIMEOUT_MS = 500;

enum Events {
  BALANCE_UPDATE = 'BALANCE_UPDATE',
  NEW_TRADE = 'NEW_TRADE',
  ESTIMATED_TRADE_RESULT = 'ESTIMATED_TRADE_RESULT',
}

export class FlipStore {
  private _socketStore: SocketStore;
  private _accountStore: AccountStore;
  private _settingsStore: SettingsStore;
  private _blockchainStore: BlockchainStore;
  private _ordersStore: OrdersStore;

  @observable
  private _error: TokenError | null;

  @observable
  private _tokenDetails: ResearchPairResponse | null;

  @observable
  private _tokenBalance: TokenBalanceResponse | null;

  @observable
  private _balance: SolanaFlipBalance | null;

  @observable
  private _address: string | null;

  @observable
  private _gas: GasResponse | null;

  @observable
  private _orders: OrderResponse[] | null;

  @observable
  private _isSearching: boolean;

  @observable
  private _isOrdersLoaded: boolean;

  @observable
  private _isMcap: boolean = true;

  @observable
  private _flipSettings: FlipSettings[];

  @observable
  private _flipOrderParams: FlipOrderParams;

  @computed
  get isPro() {
    return this._settingsStore.flipModeType === FlipModeType.PRO;
  }

  @computed
  get isSearching() {
    return this._isSearching;
  }

  @computed
  get isOrdersLoaded() {
    return this._isOrdersLoaded;
  }

  @computed
  get isLyubaMode() {
    return this.flipOrderParams.lyubaMode;
  }

  @computed
  get socket() {
    return this._socketStore.socket;
  }

  @computed
  get error() {
    return this._error;
  }

  @computed
  get address() {
    return this._address;
  }

  @computed
  get balance() {
    return this._balance;
  }

  @computed
  get networkChecked() {
    return this._accountStore.network === ChainId.SOLANA;
  }

  @computed
  get tokenDetails() {
    return this._tokenDetails;
  }

  @computed
  get tokenBalance() {
    return this._tokenBalance;
  }

  @computed
  get gas() {
    return this._gas;
  }

  @computed
  get orders() {
    return this._orders;
  }

  @computed
  get isExecuting() {
    return this.orders?.some(
      (order) =>
        [OrderType.MARKET_BUY, OrderType.MARKET_SELL].includes(order.type) &&
        order.status === OrderStatus.EXECUTING,
    );
  }

  @computed
  get nativeTokenPrice() {
    return new BigNumber(this.tokenDetails?.usdPrice || 0)
      .div(this.tokenDetails?.priceInBaseToken || 0)
      .toNumber();
  }

  @computed
  get nativeTokenPriceUsd() {
    return !this._ordersStore.balance
      ? 0
      : new BigNumber(this._ordersStore.balance.balanceInUsd)
          .div(this._ordersStore.balance.balance)
          .toNumber();
  }

  @computed
  get tokenUsdPrice() {
    if (
      this.tokenBalance?.tokenBalanceInUsd &&
      this.tokenBalance?.tokenBalanceInUsd !== '0'
    ) {
      return new BigNumber(this.tokenBalance.tokenBalanceInUsd)
        .div(this.tokenBalance.tokenBalance)
        .toString();
    }
    return this.tokenDetails?.usdPrice || '0';
  }

  @computed
  get tokenBalanceFormatted() {
    return !this.tokenBalance || !this.tokenDetails
      ? null
      : {
          balance: this.tokenBalance.tokenBalance,
          balanceInUsd: this.tokenBalance.tokenBalanceInUsd,
          token: this.getTokenParamFromDetails('symbol', this.tokenDetails),
        };
  }

  @computed
  get limitData() {
    return {
      price: this.tokenDetails?.usdPrice,
      priceBase: this.tokenDetails?.priceInBaseToken,
      mCap: this.tokenDetails?.mcap,
      liquidity: this.tokenDetails?.liquidity?.usd,
    };
  }

  @computed
  get buySell() {
    return {
      buy:
        this._tokenBalance?.totalNativeBuyValue !== undefined
          ? new BigNumber(this._tokenBalance?.totalNativeBuyValue).toNumber()
          : undefined,
      sell:
        this._tokenBalance?.totalNativeSellValue !== undefined
          ? new BigNumber(this._tokenBalance?.totalNativeSellValue).toNumber()
          : undefined,
    };
  }

  @computed
  get isMcap() {
    return this._isMcap;
  }

  @computed
  get flipSettings() {
    return Array.isArray(this._flipSettings)
      ? this._flipSettings
      : defaultFlipSettings;
  }

  @computed
  get flipOrderParams() {
    return this._flipOrderParams ?? defaultFlipAmounts;
  }

  constructor(
    socketStore: SocketStore,
    accountStore: AccountStore,
    settingsStore: SettingsStore,
    ordersStore: OrdersStore,
    blockchainStore: BlockchainStore,
  ) {
    this._socketStore = socketStore;
    this._accountStore = accountStore;
    this._settingsStore = settingsStore;
    this._ordersStore = ordersStore;
    this._blockchainStore = blockchainStore;

    makeAutoObservable(this);

    reaction(
      () => settingsStore.settings.flipSettings,
      runInAction(() => (s) => {
        const flipLocalSettings =
          getLocalStorageObject('flipLocalSettings') || {};
        if (s) {
          this._flipSettings = s;
          const tmpFlipOrderParams = clone(
            this.flipOrderParams || {},
          ) as FlipOrderParams;

          [
            'flipAmounts',
            'antiMev',
            'priorityMode',
            'activeBuySellSetting',
          ].map((key) => {
            if (flipLocalSettings[key]) {
              tmpFlipOrderParams.buyAntiMev ??= flipLocalSettings.antiMev.buy;
              tmpFlipOrderParams.sellAntiMev ??= flipLocalSettings.antiMev.sell;
              tmpFlipOrderParams.buyAmount ??= flipLocalSettings[key].buy;
              tmpFlipOrderParams.sellAmount ??= flipLocalSettings[key].sell;
            }
          });

          ['panicButton', 'useWSol', 'lyubaMode', 'useBloxroute', 'solTip'].map(
            (k) => {
              if (['solTip'].includes(k)) {
                return (tmpFlipOrderParams.solTip =
                  this.flipSettings[0].solTip);
              }
              const key = k as keyof Pick<
                FlipOrderParams,
                'panicButton' | 'useWSol' | 'lyubaMode' | 'useBloxroute'
              >;
              tmpFlipOrderParams[key] = this._flipSettings.every((i) => i[key]);
            },
          );

          this._flipOrderParams = {
            ...this.getAppliedFlipSettings({
              antiMev: false,
              priorityMode: SolPriorityMode.REGULAR,
            }),
            ...flipLocalSettings,
            ...tmpFlipOrderParams,
          };
        }
      }),
    );

    reaction(
      () => socketStore.isOnline,
      (isOnline) => {
        if (!isOnline) {
          this.socket.disconnect();
          this.socket.connect();
          this.subscribePrice();
        } else {
          this._socketStore.socket.on(Events.BALANCE_UPDATE, (data) => {
            if (!data || data.success === false) {
              console.error(data?.message || 'Unknown error');
            } else {
              this._balance = {
                ...this._balance,
                ...data.nativeBalance,
              };
      
              this._tokenBalance = {
                ...this._tokenBalance,
                ...data.tokenBalance,
              };
            }
          });
        }
      },
    );

    reaction(
      () => this._address,
      (address) => {
        if (address) {
          this.getPairDetails();
        }
      },
    );

    reaction(
      () => this.tokenDetails?.pairAddress && this._accountStore.currentWallet,
      (token) => {
        if (token) {
          this.getOrders();
          this.getTokenBalance();
        }
      },
    );

    reaction(
      () => this._accountStore.currentWallet?.type === WalletType.SOL,
      (rule) => {
        if (rule) {
          this.getBalance();
        }
      },
    );

    debouncedReaction(
      () => this.flipSettings,
      (v) => this._settingsStore.setFlipSettings(v),
      DEBOUNCE_TIMEOUT_MS,
    );

    effect(
      () => [
        this.flipOrderParams.priorityMode,
        this.flipOrderParams.buyAntiMev,
        this.flipOrderParams.sellAntiMev,
      ],
      (v, p) => {
        if (p) {
          const [priorityMode, buyAntiMev, sellAntiMev] = v.map((e, idx) =>
            idx ? !!e : e,
          ) as [SolPriorityMode, boolean, boolean, boolean];

          const [buySettings, sellSettings] = [buyAntiMev, sellAntiMev].map(
            (antiMev) => {
              return this.getAppliedFlipSettings({
                antiMev,
                priorityMode,
              });
            },
          );

          this._flipOrderParams = {
            ...this.flipOrderParams,
            priorityMode,
            buyAntiMev,
            sellAntiMev,
            buySellSettings: {
              buy: buySettings?.buySellSettings.buy ?? [null, null, null],
              sell: sellSettings?.buySellSettings.sell ?? [null, null, null],
            },
          };

          if (sellSettings) {
            this._flipOrderParams.sellSlippage = sellSettings.sellSlippage;
            this._flipOrderParams.sellFee = sellSettings.sellFee;
          }

          if (buySettings) {
            this._flipOrderParams.buySlippage = buySettings.buySlippage;
            this._flipOrderParams.buyFee = buySettings.buyFee;
          }

          updateLocalStorageObject('flipLocalSettings', {
            priorityMode,
            antiMev: {
              buy: buyAntiMev,
              sell: sellAntiMev,
            },
          });
        }
      },
    );

    debouncedReaction(
      () =>
        JSON.stringify([
          this.flipOrderParams.buyAmount,
          this.flipOrderParams.sellAmount,
        ]),
      (v) => {
        const [buy, sell] = JSON.parse(v);

        if (buy && sell) {
          updateLocalStorageObject('flipLocalSettings', {
            flipAmounts: { buy, sell },
            activeBuySellSetting: { buy, sell },
          });

          this._flipOrderParams.activeBuySellSetting = { buy, sell };
        }
      },
      0,
    );
  }

  @action
  setIsMcap(value: boolean) {
    this._isMcap = value;
  }

  @action.bound
  setRouteParams(params: FlipRouteParams) {
    if (
      params.address !== this._tokenDetails?.pairAddress &&
      params.address !== this._tokenDetails?.quoteToken.address
    ) {
      this._tokenDetails = null;
      this._orders = null;
      this._address = params.address || null;
    }
  }

  @action.bound
  setTokenDetails(token: ResearchPairResponse) {
    this._tokenDetails = token;
    // this.getOrders();
  }

  @action.bound
  async errorCatcher(response: AxiosError | TokenError) {
    if (response instanceof AxiosError && response.response?.status === 401) {
      this._accountStore.logout();
    }
    if (response instanceof AxiosError && response.response?.data) {
      console.error(response.response.data);
    }
    if (response instanceof TokenError) {
      this._error = response;
    } else {
      console.error(response);
      return response.response;
    }
  }

  @action.bound
  setFlipSettings(settings: Partial<FlipSettings>) {
    const globalProps = ['buySellSettings', 'useBloxroute', 'solTip'];
    const definingProps = ['antiMev', 'priorityMode'];
    const toggleProps = ['useWSol', 'panicButton', 'lyubaMode'];

    const [usedKey] = Object.keys(settings).filter(
      (k) => !definingProps.includes(k),
    ) as (keyof FlipSettings)[];

    if (globalProps.includes(usedKey)) {
      const newSettings = this.flipSettings.map((s) => ({
        ...s,
        [usedKey]: settings[usedKey as keyof FlipSettings],
      }));

      if (newSettings[0][usedKey] !== this.flipSettings[0][usedKey])
        this._settingsStore.setFlipSettings(newSettings, true);
      return;
    }
    if (toggleProps.includes(usedKey)) {
      const key = usedKey as keyof FlipSettings;
      const filter = this.flipSettings.map((s) => {
        //@ts-ignore
        s[key] = settings[key]!;

        return { ...s };
      });
      this._flipSettings = filter;
      this._settingsStore.setFlipSettings(filter);
    } else {
      this._flipSettings = this.flipSettings.map((obj) => {
        return definingProps.every((prop) => {
          const k = prop as keyof FlipSettings;
          return obj[k] === settings[k];
        })
          ? { ...obj, ...settings }
          : obj;
      });
    }
  }

  getFlipQuickParams({
    buyAntiMev,
    sellAntiMev,
    priorityMode,
  }: Pick<FlipOrderParams, 'buyAntiMev' | 'sellAntiMev' | 'priorityMode'>) {
    const {
      buyFee,
      buySlippage,
      buySellSettings: buySellSettingsBuy,
    } = this.getAppliedFlipSettings({
      antiMev: buyAntiMev,
      priorityMode,
    }) ?? {};
    const {
      sellFee,
      sellSlippage,
      buySellSettings: buySellSettingsSell,
      panicButton: sellPanicButton,
    } = this.getAppliedFlipSettings({
      antiMev: sellAntiMev,
      priorityMode,
    }) ?? {};

    return {
      buyFee,
      sellFee,
      buySlippage,
      sellSlippage,
      buyButtons: buySellSettingsBuy?.buy || [null],
      sellButtons: buySellSettingsSell?.sell || [null],
      sellPanicButton,
    };
  }

  @action.bound
  setFlipOrderParams(settings: Partial<FlipOrderParams>) {
    const [k] = Object.keys(settings);
    const isBuyEditing = k.includes('buy');

    const { buyAmount, sellAmount } = this.flipOrderParams;

    if (['priorityMode'].includes(k)) {
      const { buyFee, buySlippage, sellFee, sellSlippage } =
        this.getFlipQuickParams({
          buyAntiMev: this.flipOrderParams.buyAntiMev,
          sellAntiMev: this.flipOrderParams.sellAntiMev,
          priorityMode: settings.priorityMode!,
        });

      const quickSettings: Partial<FlipOrderParams> = {
        buyFee,
        buySlippage,
        sellFee,
        sellSlippage,
        buyAmount,
        sellAmount,
      };

      this._flipOrderParams = {
        ...clone(this.flipOrderParams),
        ...clone(settings),
        ...clone(quickSettings),
      };
    } else if (['buyAntiMev'].includes(k)) {
      const { buyFee, buySlippage } = this.getFlipQuickParams({
        buyAntiMev: settings.buyAntiMev!,
        sellAntiMev: this.flipOrderParams.sellAntiMev,
        priorityMode: this.flipOrderParams.priorityMode,
      });

      const quickSettings: Partial<FlipOrderParams> = {
        buyFee,
        buySlippage,
        buyAmount,
      };

      this._flipOrderParams = {
        ...this._flipOrderParams,
        ...settings,
        ...quickSettings,
      };
    } else if (['sellAntiMev'].includes(k)) {
      const { sellFee, sellSlippage } = this.getFlipQuickParams({
        buyAntiMev: this.flipOrderParams.buyAntiMev,
        sellAntiMev: settings.sellAntiMev!,
        priorityMode: this.flipOrderParams.priorityMode,
      });

      const quickSettings: Partial<FlipOrderParams> = {
        sellFee,
        sellSlippage,
        sellAmount,
      };

      this._flipOrderParams = {
        ...this._flipOrderParams,
        ...settings,
        ...quickSettings,
      };
    } else {
      const { priorityMode, buyAntiMev, sellAntiMev } = this.flipOrderParams;
      const params = { ...this._flipOrderParams, ...settings };

      const preparedSetting = Object.fromEntries(
        Object.entries(settings as Record<string, string>).map(([k, v]) => [
          k,
          new BigNumber(v).toNumber(),
        ]),
      );

      this._flipOrderParams = params;

      this.setFlipSettings({
        priorityMode,
        antiMev: isBuyEditing ? buyAntiMev : sellAntiMev,
        ...preparedSetting,
      });
    }
  }

  @action.bound
  async resetFlipSettings() {
    const { settings } = this._settingsStore;

    const flipSettings: never[] = [];

    const res = await this._settingsStore.resetSettings({
      ...settings,
      flipSettings,
    });

    if (res.success) {
      const {
        data: { flipSettings },
      } = res as {
        success: boolean;
        data: UserSettings;
      };

      if (flipSettings) {
        this._flipSettings = flipSettings;
        this.setFlipOrderParams(flipSettings[0]);
        removeLocalStorageObject('flipLocalSettings');
      }
    }
  }

  @action.bound
  async updateFlipSettings() {
    this._settingsStore.setFlipSettings(this.flipSettings);
  }

  @action.bound
  getAppliedFlipSettings({
    antiMev,
    priorityMode,
  }: {
    antiMev: FlipSettings['antiMev'];
    priorityMode: SolPriorityMode;
  }) {
    try {
      return (this._flipSettings ?? defaultFlipSettings).find(
        (s) => s.antiMev === antiMev && s.priorityMode === priorityMode,
      )!;
    } catch (e) {
      return defaultFlipSettings.find(
        (s) => s.antiMev === antiMev && s.priorityMode === priorityMode,
      );
    }
  }

  @action.bound
  async getOrders(noLoader = false) {
    if (!noLoader) {
      this._isOrdersLoaded = false;
    }
    const types = allOrderFilters.map((v) => `type=${v}`).join('&');

    return api<Paged<OrderResponse[]>>({
      method: 'get',
      path: `/order?size=100&${types}`,
      data: {
        walletId: this._accountStore.currentWallet?.id,
        pairAddress: this.tokenDetails?.pairAddress,
        filter: 'EXECUTED',
      },
    })
      .then((response) => {
        this._isOrdersLoaded = true;
        const result = response?.content ?? [];
        this._orders = result;
        return result;
      })
      .catch((response) => {
        this._isOrdersLoaded = true;
        console.error('failed to get orders', response);
        this.errorCatcher(
          new TokenError({
            ...(response.response?.data && { message: 'errors.network-error' }),
            navigate: PageRoutes.LIMIT_ORDERS,
          }),
        );
      });
  }

  getTokenParamFromDetails(
    key: 'address' | 'name' | 'symbol',
    details: ResearchPairResponse | null,
  ): string;
  getTokenParamFromDetails(
    key: 'address' | 'name' | 'symbol',
    details: OrdersSummaryResponse | null,
  ): string;
  getTokenParamFromDetails(
    key: 'address' | 'name' | 'symbol',
    details: PortfolioToken | null,
  ): string;
  getTokenParamFromDetails(
    key: string,
    details:
      | ResearchPairResponse
      | OrdersSummaryResponse
      | PortfolioToken
      | null,
  ) {
    if (details === null) return '';

    const address =
      (details as ResearchPairResponse)?.quoteToken?.address ||
      (details as OrdersSummaryResponse)?.tokenAddress ||
      (details as PortfolioToken)?.address;

    const name =
      (details as ResearchPairResponse)?.quoteToken?.name ||
      (details as OrdersSummaryResponse)?.tokenName ||
      (details as PortfolioToken)?.name;

    const symbol =
      (details as ResearchPairResponse)?.quoteToken?.symbol ||
      (details as OrdersSummaryResponse)?.tokenSymbol ||
      (details as PortfolioToken)?.symbol;

    return { address, name, symbol }[key];
  }

  @action.bound
  async getTokenBalance() {
    return api<TokenBalanceResponse>({
      method: 'get',
      path: `/balance/token`,
      data: {
        walletId: this._accountStore.currentWallet?.id,
        tokenAddress: this.getTokenParamFromDetails(
          'address',
          this.tokenDetails,
        ),
        blockchain: ChainId.SOLANA,
      },
    })
      .then((response) => {
        this._tokenBalance = response;

        return response;
      })
      .catch((response) => {
        console.error('failed to get token balance', response);
        this.errorCatcher(
          new TokenError({
            ...(response.response?.data && { message: 'errors.network-error' }),
          }),
        );
      });
  }

  @action.bound
  async getBalance() {
    return api<SolanaFlipBalance>({
      method: 'get',
      path: `/sol-flip/balances`,
      data: {
        walletId: this._accountStore.currentWallet?.id,
      },
    })
      .then((response) => {
        this._balance = response;

        return response;
      })
      .catch((response) => {
        console.error('failed to get balance', response);
        this.errorCatcher(
          new TokenError({
            ...(response.response?.data && { message: 'errors.network-error' }),
          }),
        );
      });
  }

  @action.bound
  async swapBalance(amount: string, type: 'wrap' | 'unwrap') {
    return api<CommandResultResponse>({
      method: 'post',
      path: `/sol-flip/${type}`,
      data: {
        amount,
        walletId: this._accountStore.currentWallet?.id,
      },
    })
      .then((response) => {
        this.getBalance();
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async getPairDetails() {
    this._isSearching = true;
    this.unsubscribePrice();
    return api<ResearchPairResponse[]>({
      method: 'get',
      path: `/pair/search`,
      data: {
        q: this.address,
        pairType: [PairType.RAY_V4, PairType.PUMPFUN],
      },
    })
      .then((response) => {
        this._isSearching = false;
        if (response && !response.length)
          throw new TokenError({
            message: 'Get pair details error',
          });

        this._isOrdersLoaded = false;
        this._orders = null;
        this.setTokenDetails(response[0]);
        this.subscribePrice();
      })
      .catch((response) => {
        this._isSearching = false;
        console.error('failed to get pair details', response);
        this.errorCatcher(response);
      });
  }

  @action.bound
  async subscribePrice() {
    if (!this.socket) {
      setTimeout(() => this.subscribePrice(), 500);
      return;
    }

    this.socket.on(
      TokenEvents.SUBSCRIBE_PRICE_UPDATE,
      (data: PriceUpdateResponse) => {
        if (
          this.tokenDetails?.pairAddress.toLowerCase() ===
          data.pairAddress.toLowerCase()
        ) {
          runInAction(() => {
            if (this.tokenDetails)
              this._tokenDetails = getUpdatedTokenDetails(
                this.tokenDetails,
                data,
              );

            if (this.tokenBalance)
              this._tokenBalance = getUpdatedTokenBalance(
                this.tokenBalance,
                data,
              );
          });
        }
      },
    );

    this.socket.emit(TokenEvents.SUBSCRIBE_PRICE_UPDATE, {
      blockchain: ChainId.SOLANA,
      pairAddresses: [this.address],
    });
  }

  @action setLatestOrder(data: OrderResponse) {
    this._orders = [data, ...(this._orders ?? [])].filter((o) => o.id !== data.metadata.frontendId);

    this.socket.emit(Events.BALANCE_UPDATE, {
      walletId: this._accountStore.currentWallet?.id,
    });
  }

  @action
  async createOrder(data: CreateOrderRequest) {
    const frontendId = `${Date.now()}-${Math.random().toString(36).substring(7)}`;
    data.frontendId = frontendId;
    this._orders = [
      {
        ...data,
        status: OrderStatus.EXECUTING,
        id: frontendId,
        createdAt: new Date(),
        pairId: '',
        transactions: [],
        dex: Dex.PUMPFUN,
        tokenName: this.tokenDetails?.quoteToken?.name || '',
        tokenSymbol: this.tokenDetails?.quoteToken?.symbol || '',
        metadata: {
          ...data.metadata,
          executionMcap: this.tokenDetails?.mcap,
          frontendId,
        },
        pairType: PairType.PUMPFUN,
        baseToken: this.tokenDetails!.baseToken.symbol as BaseToken,
        targetTriggerValue: '0',
        settings: defaultOrderSettings(Blockchain.SOLANA),
        trigger: OrderTrigger.PRICE_IN_USD,
        tokenAddress: this.tokenDetails!.quoteToken.address,
        updatedAt: new Date(),
        usdValue: '0',
        valueIn: data.valueIn,
        valueOut: '0',
        nativeTokenUsdPrice: '0',
        initialTriggerValue: '0',
        walletAddress: this._accountStore.currentWallet?.address || '',
      },
      ...(this._orders ?? []),
    ];


    return new Promise((resolve, reject) => {
      console.time('estimatedTradeResult');
      this.socket.once(
        Events.ESTIMATED_TRADE_RESULT,
        (data: { sol: number; token: number; tokenMcap: number, frontendId: string }) => {
          console.timeEnd('estimatedTradeResult');
          this._orders = (this._orders || []).map((o) => {
            if (o.metadata.frontendId === frontendId && o.status === OrderStatus.EXECUTING) {
              const fakeOrder = o;
              const sol = data.sol.toString();
              const token = data.token.toString();
              fakeOrder.metadata.executionMcap = data.tokenMcap.toString();
              fakeOrder.status = OrderStatus.ESTIMATED;
              if (fakeOrder.type === OrderType.MARKET_BUY) {
                fakeOrder.valueIn = sol;
                fakeOrder.valueOut = token;
              } else {
                fakeOrder.valueIn = token;
                fakeOrder.valueOut = sol;
              }
              return fakeOrder;
            }
            return o;
          });
        },
      );

      console.time('newTrade');
      this.socket.once(Events.NEW_TRADE, (data) => {
        console.timeEnd('newTrade');
        if (!data || data.success === false) {
          reject({ success: false, data: data?.message || 'Unknown error', frontendId });
        } else {
          resolve({ success: true, data, frontendId });
        }
      });

      this.socket.emit(Events.NEW_TRADE, data);
    });
  }

  @action.bound
  async unsubscribePrice() {
    this.socket?.emit(TokenEvents.UNSUBSCRIBE_PRICE_UPDATE);
  }

  @action.bound
  clearHistoryPendingItem(frontendId: string) {
    const list = this._orders?.filter(
      (o) => o.id !== frontendId,
    );

    if (list) this._orders = list;
  }

  @action
  clearError() {
    this._error = null;
  }

  @action.bound
  async reset() {
    this.unsubscribePrice();
    this._error = null;
    this._orders = null;
    this._address = null;
    this._gas = null;
    this._tokenDetails = null;
    this._tokenBalance = null;
  }

  @action.bound
  errorHandler(error: AxiosError | any) {
    return this._accountStore.errorHandler(error);
  }
}
