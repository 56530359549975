import React from 'react';
import { convertStringToNumber } from '@helpers/bignumber';
import BigNumber from 'bignumber.js';

export const randomNumber = () => Math.floor(200000000 + Math.random() * 900000000000000);

export const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max);
};

export const round = (num: string | number | BigNumber, decimals: number) => {
  const factor = 10 ** decimals;
  return Math.round(convertStringToNumber(num + '') * factor) / factor;
};

export const repairPrecision = (num: number) => round(num, 10);

export const roundUp = (num: string | number | BigNumber, decimals: number) => {
  num = repairPrecision(convertStringToNumber(num + ''));
  const factor = 10 ** decimals;
  const magnified = repairPrecision(num * factor);
  return Math.ceil(magnified) / factor;
};

export const roundDown = (num: string | number | BigNumber, decimals: number) => {
  num = repairPrecision(convertStringToNumber(num + ''));
  const factor = 10 ** decimals;
  const magnified = repairPrecision(num * factor);
  return Math.floor(magnified) / factor;
};

export const numberWithCommas = (x: number | string, floor: boolean | undefined, decimals: number | undefined, subZeros = false, shorten = 0) => {
  let value = decimals !== undefined && !subZeros ? round(x, decimals) : x;

  if (floor === true && decimals !== undefined) {
    value = roundDown(x, decimals);
  } else if (floor === false && decimals !== undefined) {
    value = roundUp(x, decimals);
  }

  if (!value && x > value) {
    value = x;
  }

  const stringValue = new BigNumber(value).toFixed();

  const integer = parseInt(stringValue);
  let digits = stringValue.split('.')[1];
  const result = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const digitsLength = decimals !== undefined ? decimals : 2;

  if (subZeros && digits) {
    let zeros = 0;
    let leftover = '';
    for (let i = 0; i < digits.length; i++) {
      if (digits[i] === '0') {
        zeros++;
      } else {
        leftover = digits.substring(i, i + digitsLength);
        break;
      }
    }

    while (leftover[leftover.length - 1] === '0') {
      leftover = leftover.substring(0, leftover.length - 1);
    }

    let subText = ''; // ₀
    switch (zeros) {
      case 1:
        subText = '₁';
        break;
      case 2:
        subText = '₂';
        break;
      case 3:
        subText = '₃';
        break;
      case 4:
        subText = '₄';
        break;
      case 5:
        subText = '₅';
        break;
      case 6:
        subText = '₆';
        break;
      case 7:
        subText = '₇';
        break;
      case 8:
        subText = '₈';
        break;
      case 9:
        subText = '₉';
        break;
      default:
        break;
    };


    if (integer === 0) {
      return `${integer}.0${subText}${leftover}`;
      // return <>{integer}.0<sub>{zeros}</sub>{leftover}</>;
    }
  }
  if (digits) {
    let cutZeroes = digits.substring(0, digitsLength);
    while (cutZeroes[cutZeroes.length - 1] === '0') {
      cutZeroes = cutZeroes.substring(0, cutZeroes.length - 1);
    }
    digits = cutZeroes;
  }
  const resultString = `${result}${digits ? '.' + digits.substring(0, digitsLength) : ''}`;
  const commas = result.split(',').length;
  return shorten && integer.toString().length > shorten ? resultString.substring(0, shorten + (commas > shorten / 3 ? Math.floor(shorten / 3) - 1 : commas)) + '...' : resultString;
};

export const bigNumberToNumber = (num: BigNumber, fromWei = true) => {
  // const n = fromWei ? Moralis.Units.FromWei(num.toString()) : num.toString();
  // return convertStringToNumber(n);
  return convertStringToNumber(num.toString());
};

export const formatNumber = (_value: number | string, unit = '', decimals = 2) => {
  let number;
  const value = new BigNumber(_value).toNumber();
  const suffix = unit === '$' ? '$' : '';
  let postfix = unit === '%' ? '%' : '';

  if (value > 1000) {
    let divider = 1000;
    postfix = 'K';
    if (value >= 1000000) {
      divider = 1000000;
      postfix = 'M';
    }
    if (value >= 1000000000) {
      divider = 1000000000;
      postfix = 'B';
    }
    number = new BigNumber(value).div(divider).toFormat(decimals, 1).toString();
  } else {
    number = new BigNumber(value).toFormat(decimals, 1).toString();
  }
  // const removeDigits = (postfix === 'M' || postfix === 'B') && parseInt(number).toString().length > 1;
  const sliced = number.split('.');
  // if (removeDigits || (sliced.length === 2 && (sliced[1] == '0' || sliced[1] == '00'))) {
  if (sliced.length === 2 && (sliced[1] == '0' || sliced[1] == '00')) {
    number = sliced[0];
  }
  if (!suffix && unit && postfix !== unit) {
    postfix += ' ' + unit;
  }
  return suffix + number + postfix;
};

export const getPercentage = (value: number | string, total: number | string) => {
  return new BigNumber(value).div(total).times(100).toNumber();
};

export const getPercentageOf = (value: number, total: number) => {
  return new BigNumber(value).div(100).times(total).toNumber();
};

export const getPercentageDifference = (value: number | string, total: number | string) => {
  return new BigNumber(value).minus(total).div(total).times(100).toNumber();
};

export const toWei = (value: number | string, decimals = 18) => {
  const splitted = new BigNumber(value).toFixed(decimals, 1).split('.');
  const zerosCount = decimals - (splitted.length === 2 ? splitted[1].length : 0);
  let result = splitted[0];
  if (result === '0') {
    result = '';
    if (splitted.length === 2) {
      let notZero = false;
      splitted[1].split('').forEach((char) => {
        if (char !== '0' || notZero) {
          notZero = true;
          result += char;
        }
      });
    }
  } else {
    result += splitted.length === 2 ? splitted[1] : '';
  }
  const zeros = Array(zerosCount + 1).join('0');
  return result + zeros;
};

export const fromWei = (value: number | string, decimals = 18) => {
  return new BigNumber(value).div(10 ** decimals).toNumber();
};

export const calcDigits = (value: number | string) => {
  const n = new BigNumber(value);
  if (n.isZero()) {
    return 0;
  }
  if (n.isLessThan(0.000001)) {
    return 9;
  }
  if (n.isLessThan(0.00001)) {
    return 5;
  }
  if (n.isLessThan(0.0001)) {
    return 4;
  }
  if (n.isLessThan(0.001)) {
    return 3;
  }
  return 2;
};

export function roundLastNonZero(num: number | string) {
  const numStr = num.toString();

  const match = numStr.match(/^-?0\.(0*)(\d+)/);
  if (match) {
    const leadingZeros = match[1].length;
    const significantDigits = match[2].slice(0, 4);
    return new BigNumber(`0.${'0'.repeat(leadingZeros)}${significantDigits}`).toNumber();
  }

  return new BigNumber(num).toFixed(4);
}
