export interface ApiReferralLine {
  userId: string;
  line: number;
  turnover: string;
  turnover30d: string;
  profit: string;
  totalProfit: string;
  profit30d: string;
  refCount: number;
}

export interface ApiReferral {
  refId: string;
  totalProfit: number;
  unclaimedProfit: number;
  userRank: number;
  personalTurnover: number;
  referralTurnover: number;
  totalTurnover: number;
  personal30dTurnover: number;
  referral30dTurnover: number;
  total30dTurnover: number;
  refLines: ApiReferralLine[];
}

export enum ReferralLevelType {
  BRONZE = 0,
  SILVER = 1,
  GOLD = 2,
  PLATINUM = 3,
  DIAMOND = 4,
  MASTER = 5,
  ULTRA = 6,
}

export interface ReferralLevel {
  type: ReferralLevelType;
  turnover: number;
  refPercent1Line: number;
  refPercent2Line: number;
  label: string;
}

export const RANK_RULES = [
  {
    rank: 0,
    label: 'Bronze',
    min30dUsdVolume: 0,
    refRewardPercents: [10, 5],
  },
  {
    rank: 1,
    label: 'Silver',
    min30dUsdVolume: 100000,
    refRewardPercents: [12, 8],
  },
  {
    rank: 2,
    label: 'Gold',
    min30dUsdVolume: 500000,
    refRewardPercents: [15, 10],
  },
  {
    rank: 3,
    label: 'Platinum',
    min30dUsdVolume: 1000000,
    refRewardPercents: [20, 10],
  },
  {
    rank: 4,
    label: 'Diamond',
    min30dUsdVolume: 2500000,
    refRewardPercents: [25, 10],
  },
  {
    rank: 5,
    label: 'Master',
    min30dUsdVolume: 5000000,
    refRewardPercents: [30, 10],
  },
  // {
  //   rank: 6,
  //   label: 'Ultra',
  //   refRewardPercents: [35, 5],
  // },
];
