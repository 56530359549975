import React, { useCallback, useMemo } from 'react';
import FormattedNumber from '@components/common/FormattedNumber';
import BigNumber from 'bignumber.js';
import { OrderResponse } from '../../../types/order/order.response';
import useOrderData from '@hooks/useOrderData';
import Progress from '@components/common/Progress';
import { LimitData } from '../../../types/common.types';
import clock from '@assets/icons/clock-yellow.svg';
import { OrderType } from '../../../types/order/order.enum';
import { getPercentageDifference } from '@helpers/numbers';
import { useTranslation } from 'react-i18next';

interface LimitOrderHistoryItemProps {
  item: OrderResponse;
  isLast: boolean;
  onClick: () => void;
  limitData: LimitData;
  nativePrice: number;
}

function calcProgressBarFillPercents(
  initialPrice: string | number,
  currentPrice: string | number,
  triggerPrice: string | number,
  orderType: OrderType,
) {
  let value = 0,
    max = 0;
  if (orderType === OrderType.LIMIT_SELL) {
    max = new BigNumber(triggerPrice).minus(initialPrice).toNumber();
    value = new BigNumber(currentPrice).minus(initialPrice).toNumber();
  }

  if (orderType === OrderType.LIMIT_BUY || orderType === OrderType.STOP_LOSS) {
    max = new BigNumber(initialPrice).minus(triggerPrice).toNumber();
    value = new BigNumber(initialPrice).minus(currentPrice).toNumber();
  }

  return Math.min(Math.max(value / max || 0, 0), 1) * 100;
}

const LimitOrderHistoryItem = ({
  item,
  isLast,
  onClick,
  limitData,
  nativePrice,
}: LimitOrderHistoryItemProps) => {
  const { tokenSymbol, baseToken, valueIn, status, usdValue } = item;

  const {
    isMarket,
    isBuy,
    isStopLoss,
    isError,
    isExecuting,
    errorText,
    typeName,
    typeClass,
    statusTextDated,
    amountOut,
    amountIn,
    isOpen,
    isCompleted,
    targetTriggerValue,
    initialTriggerValue,
    nextTriggerValue,
    currentData,
    triggerName,
    limitPrice,
    usdPrice,
  } = useOrderData(item, limitData, nativePrice);

  const { t } = useTranslation();

  // const diff = useMemo(() => {
  //   return getPercentageDifference()
  // };

  const progressValues = useMemo(() => {
    return {
      target: new BigNumber(nextTriggerValue || targetTriggerValue),
      current: new BigNumber(currentData.value),
    };
  }, [targetTriggerValue, currentData]);

  const formatDecimals = useCallback((value: BigNumber) => {
    const abs = value.abs();
    if (abs.isGreaterThan(100)) {
      return 2;
    }
    return abs.isLessThan(0.000001) ? 10 : 6;
  }, []);

  const isPriceUp = useMemo(() => {
    return new BigNumber(usdPrice).isGreaterThan(
      new BigNumber(limitData.price || 0),
    );
  }, [usdPrice, limitData.price]);

  const percentDiff = useMemo(() => {
    return getPercentageDifference(limitData.price || 0, usdPrice.toNumber());
  }, [usdPrice, limitData.price]);

  const useFormat = useMemo(() => {
    return progressValues.target.isGreaterThan(99000);
  }, [progressValues.target]);

  const getDecimalsWithFormat = (value: BigNumber) => {
    const registersCount = value.toFixed(0).toString().length - 1;
    const isRoundNumber = registersCount % 3 === 0;

    if (isRoundNumber) {
      return 2;
    }

    return 0;
  }

  const progressDecimals = useMemo(() => {
    if (useFormat) {
      return getDecimalsWithFormat(progressValues.target);
    }

    return currentData.isUsd ? 4 : formatDecimals(progressValues.target);
  }, [currentData.isUsd, progressValues.target, useFormat]);

  return (
    <div
      className={`pb-3 mb-3 ${isLast ? '' : 'border-1 border-bottom border-muted-10'} cur-pointer with-hover`}
      onClick={onClick}
    >
      {/*<div className={`pb-3 mb-3 ${isLast ? '' : 'border-1 border-bottom border-muted-10'}`}>*/}
      <div className={`operation ${typeClass}`}>
        <div className="d-flex wd-100p flex-column">
          <div className="d-flex justify-content-between align-items-center tx-17 tx-semibold">
            <div>
              <span className="align-middle">{typeName}</span>
              {(item.metadata.parentOrderId || item.metadata.tpSlOrders) && (
                <span className="badge ms-1 px-1 bg-semi-primary-10 tx-teal tx-normal rounded-5-px">
                  TP/SL
                </span>
              )}
            </div>
            {!limitPrice && !isError && isCompleted && (
              <div className="tx-right">
                <FormattedNumber
                  value={amountIn.toNumber()}
                  postfix={tokenSymbol}
                  decimals={formatDecimals(amountIn)}
                  className={`${!isBuy ? 'tx-success' : 'tx-white'}`}
                  withSign
                  subZeros
                  useFormat={amountIn.isGreaterThan(99000)}
                />
                <FormattedNumber
                  value={usdValue}
                  className={`${!isBuy ? 'tx-success' : 'tx-white'}`}
                  suffix=" ($"
                  postfix=")"
                  noSpace
                  subZeros
                />
              </div>
            )}
            {(isError || !isCompleted) && (
              <FormattedNumber
                value={valueIn}
                postfix={isBuy ? baseToken : tokenSymbol}
                decimals={formatDecimals(new BigNumber(valueIn))}
                useFormat={new BigNumber(valueIn).isGreaterThan(99000)}
                className="tx-right"
                suffix="-"
                subZeros
              />
            )}
          </div>
          <div className="d-flex justify-content-between align-items-center tx-13 tx-muted">
            <div>{statusTextDated}</div>
            {isError && <div className="tx-danger">{errorText}</div>}
            {isExecuting && !isCompleted && !isError && (
              <div className="tx-warning">
                {t('common.pending')}{' '}
                <img src={clock} alt="clock" className="ms-1" />
              </div>
            )}
            {!!limitPrice && !isError && (
              <FormattedNumber
                value={limitPrice}
                suffix="$"
                withSign={!isBuy}
                subZeros
                useFormat={new BigNumber(limitPrice).isGreaterThan(99000)}
                className="tx-right"
              />
            )}
            {!limitPrice && isCompleted && (
              <div
                className={`d-flex align-items-center justify-content-end tx-right ${!isBuy ? '' : isPriceUp ? 'tx-danger' : 'tx-success'}`}
              >
                <FormattedNumber
                  value={usdPrice.toNumber()}
                  suffix={`${t('common.price')} $`}
                  subZeros
                  decimals={usdPrice.isLessThan(1) ? 4 : 0}
                  className={isBuy ? 'me-1' : ''}
                />
                {isBuy && (
                  <>
                    {'('}
                    {isPriceUp ? '-' : '+'}
                    <FormattedNumber
                      value={percentDiff}
                      decimals={percentDiff < 1 ? 2 : 0}
                      postfix="%)"
                    />
                  </>
                )}
              </div>
            )}
          </div>
          {!isMarket && !isCompleted && (
            <Progress
              value={calcProgressBarFillPercents(
                initialTriggerValue,
                currentData.value,
                targetTriggerValue,
                item.type,
              )}
              max={100}
              className="mt-2"
              forceColor={isBuy ? 'dark-green' : 'dark-red'}
            >
              {t('common.when')} {triggerName} {currentData.sign}
              <FormattedNumber
                value={progressValues.target.toNumber()}
                decimals={progressDecimals}
                useFormat={useFormat}
                suffix={currentData.isUsd ? '$' : undefined}
                postfix={!currentData.isUsd ? baseToken : undefined}
                className="ms-1"
                subZeros
              />
            </Progress>
          )}
        </div>
      </div>
    </div>
  );
};

export default LimitOrderHistoryItem;
