import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericType } from '../../../../types';
import NumericInput from '@components/common/NumericInput';
import Toggle from 'react-toggle';
import SolanaFlipButtons from '@pages/LimitOrders/SolanaFlip/components/SolanaFlipButtons';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import { FlipSettings } from 'types/flip/flip-settings.request';
import { runInAction } from 'mobx';
import BigNumber from 'bignumber.js';
import CounterInput from '@components/common/CounterInput';
import LabeledCard from '@components/common/LabeledCard';
import bloxroute from '@assets/images/bloxroute-b.png';

interface BuySellSettings {
  buy: NumericType[];
  sell: NumericType[];
}

const SolanaFlipSettings = observer(() => {
  const { t } = useTranslation();
  const { flipStore } = useStores();
  const { flipOrderParams, isPro } = flipStore;

  const { priorityMode, antiMev, buySellSettings } = flipOrderParams;

  const handleChange = useCallback(
    (key: 'buy' | 'sell', index: number, value: NumericType) => {
      runInAction(() => (buySellSettings[key][index] = value));
      setSetting('buySellSettings')(buySellSettings);
    },
    [buySellSettings],
  );

  const setSetting =
    (k: keyof FlipSettings) => (v: NumericType | boolean | BuySellSettings) => {
      const numberProps = [
        'buyFee',
        'sellFee',
        'buySlippage',
        'sellSlippage',
        'solTip',
      ];
      const value =
        numberProps.includes(k) && typeof v === 'string'
          ? new BigNumber(v).toNumber()
          : v;

      flipStore.setFlipSettings({ priorityMode, antiMev, [k]: value });
    };

  return (
    <div>
      <div className="mt-3 px-3">
        <div className="tx-muted tx-12 mb-1">
          {t('trading.fast-buy-sell-settings')}
        </div>
        <div className="row">
          <div className="col-6 pe-1">
            {Array(3)
              .fill(0)
              .map((_, i) => (
                <div key={`fast-buy-input-${i}`} className="mb-2">
                  <NumericInput
                    className="form-control appearance-none py-1 bg-semi-transparent-10 border border-semi-transparent"
                    value={
                      buySellSettings.buy[i] !== undefined
                        ? buySellSettings.buy[i]
                        : null
                    }
                    onChange={(v) => handleChange('buy', i, v)}
                    postfix=" SOL"
                    placeholder="2 SOL"
                  />
                </div>
              ))}
          </div>
          <div className="col-6 ps-1">
            {Array(3)
              .fill(0)
              .map((_, i) => (
                <div key={`fast-sell-input-${i}`} className="mb-2">
                  <NumericInput
                    className="form-control appearance-none py-1 bg-semi-transparent-10 border border-semi-transparent"
                    value={
                      buySellSettings.sell[i] !== undefined
                        ? buySellSettings.sell[i]
                        : null
                    }
                    onChange={(v) => handleChange('sell', i, v)}
                    postfix="%"
                    placeholder="100%"
                  />
                </div>
              ))}
          </div>
        </div>
        <div className="mb-3">
          {(
            [
              'panicButton',
              'useWSol',
              'lyubaMode',
              'useBloxroute',
            ] as (keyof FlipSettings)[]
          ).map((key) => {
            return key === 'useWSol' && !isPro ? null : (
              <>
                <div key={key} className="d-flex align-items-center gap-2">
                  <Toggle
                    icons={false}
                    className="styled-toggle mini my-2"
                    checked={!!flipOrderParams[key]}
                    onChange={(e) => setSetting(key)(e.target.checked)}
                  />
                  <span className="tx-white tx-13 tx-bold">
                    {key === 'panicButton' && t('trading.panic-sell-button')}
                    {key === 'useWSol' && t('trading.use-wsol')}
                    {key === 'lyubaMode' && t('trading.focus-mode')}
                    {key === 'useBloxroute' && t('trading.use-bloxroute')}
                  </span>
                </div>

                {key === 'useBloxroute' && flipOrderParams.useBloxroute && (
                  <div className="wd-50p">
                    <LabeledCard
                      icon={
                        <img
                          src={bloxroute}
                          alt="bloxroute"
                          width={24}
                          height={24}
                        />
                      }
                      className="mt-1"
                      labelClassName="tx-10 tx-danger ms-1 me-2"
                      horizontal
                    >
                      <CounterInput
                        value={flipOrderParams.solTip}
                        onChange={setSetting('solTip')}
                        step={0.001}
                        min={0.001}
                        max={1}
                        postfix=" SOL"
                        placeholder="0.001 SOL"
                      />
                    </LabeledCard>
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>

      <SolanaFlipButtons isPlaceholder />
      <SolanaFlipButtons />
    </div>
  );
});

export default SolanaFlipSettings;
