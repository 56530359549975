import React, { ReactNode, useMemo } from 'react';
import { SolPriorityMode } from '../../../../types/user/settings.types';
import LabeledCard from '@components/common/LabeledCard';
import Toggle from 'react-toggle';
import CounterInput from '@components/common/CounterInput';
import { NumericType } from '../../../../types';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import {
  FlipOrderParams,
  FlipSettings,
} from 'types/flip/flip-settings.request';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Vibrutton from '@components/common/Vibrutton';
import { ReactComponent as rabbit } from '../assets/rabbit.svg';
import { ReactComponent as car } from '../assets/car.svg';
import { ReactComponent as turbo } from '../assets/turbo.svg';
import { ReactComponent as Gas } from '../assets/gas.svg';
import { ReactComponent as Slippage } from '../assets/slippage.svg';
import bloxroute from '@assets/images/bloxroute-b.png';

const modes = [
  SolPriorityMode.REGULAR,
  SolPriorityMode.FAST,
  SolPriorityMode.DEGEN,
];

const icons = [rabbit, car, turbo];

const BloxSwitcher: React.FC<{ children: JSX.Element; isBuy: boolean }> =
  observer(({ children, isBuy }) => {
    const { flipStore } = useStores();

    const { flipSettings, flipOrderParams } = flipStore;

    const { priorityMode, buyAntiMev, sellAntiMev } = flipOrderParams;

    const antiMev = isBuy ? buyAntiMev : sellAntiMev;

    const appliedFlipSettings = useMemo(
      () =>
        flipStore.getAppliedFlipSettings({
          antiMev: isBuy,
          priorityMode,
        }) ?? ({} as FlipSettings),
      [flipOrderParams, flipSettings],
    );

    return appliedFlipSettings.useBloxroute && antiMev ? (
      <LabeledCard
        icon={<img src={bloxroute} alt="bloxroute" width={24} height={24} />}
        labelClassName="tx-10 tx-danger ms-1 me-2"
        horizontal
      >
        <CounterInput
          noButtons
          value={flipOrderParams.solTip}
          onChange={() => null}
          disabled
          step={0.001}
          min={0.001}
          max={1}
          postfix=" SOL"
          placeholder="0.001 SOL"
        />
      </LabeledCard>
    ) : (
      children
    );
  });

const SolanaFlipMarketQuickSettings = observer(() => {
  const { t } = useTranslation();
  const { flipStore } = useStores();
  const { flipOrderParams } = flipStore;

  const {
    buyAmount,
    sellAmount,
    priorityMode,
    buyAntiMev,
    sellAntiMev,
    buyFee,
    sellFee,
    buySlippage,
    sellSlippage,
    activeBuySellSetting,
    useWSol,
  } = flipOrderParams;

  const selectedMode = useMemo(
    () => modes.findIndex((m) => m === priorityMode),
    [priorityMode],
  );

  const symbol = useMemo(() => {
    return useWSol ? 'WSOL' : 'SOL';
  }, [useWSol]);

  const setOrderParams =
    <T,>(key: keyof FlipOrderParams) =>
    (value: T) =>
      flipStore.setFlipOrderParams({ [key]: value as T });

  return (
    <div
      className={cn(
        'px-3 mt-1 border-bottom border-semi-transparent solana-flip-market-quick-settings',
      )}
    >
      <div className="d-flex flex-row wd-100p gap-2">
        <div className="card flex-row rounded-30 py-0 pe-0 ps-2 align-items-center ht-30">
          <span className="tx-10 tx-muted me-3">{t('common.speed')}</span>
          {modes.map((mode, index) => (
            <Vibrutton
              className={cn(
                'btn',
                'btn-link',
                'rounded-30',
                'border border-solid border-1',
                'wd-30',
                'ht-30',
                'p-0',
                'd-flex align-items-center justify-content-center',
                selectedMode === index
                  ? 'tx-primary border-primary bg-semi-primary-10'
                  : 'tx-white border-secondary',
              )}
              onClick={() =>
                setOrderParams<SolPriorityMode>('priorityMode')(mode)
              }
              key={`solana-flip-mode-${mode}`}
            >
              {React.createElement(icons[index], { width: 16, height: 16 })}
            </Vibrutton>
          ))}
        </div>
        <div className="card flex-row align-items-center px-2 py-0 flex-1 rounded-30 tx-10 gap-1 ht-30">
          <span className="tx-success">B</span>
          <span className="tx-muted">Jito</span>
          <Toggle
            className="styled-toggle mini ms-auto"
            icons={false}
            checked={buyAntiMev}
            onChange={({ target: { checked } }) =>
              setOrderParams<boolean>('buyAntiMev')(
                checked as unknown as boolean,
              )
            }
          />
        </div>
        <div className="card flex-row align-items-center px-2 py-0 flex-1 rounded-30 tx-10 gap-1 ht-30">
          <span className="tx-danger">S</span>
          <span className="tx-muted">Jito</span>
          <Toggle
            className="styled-toggle mini ms-auto"
            icons={false}
            checked={sellAntiMev}
            onChange={({ target: { checked } }) =>
              setOrderParams<boolean>('sellAntiMev')(
                checked as unknown as boolean,
              )
            }
          />
        </div>
      </div>

      <div className="d-flex flex-row gap-2 mt-2">
        <BloxSwitcher isBuy={true}>
          <LabeledCard
            miniLabel="b"
            icon={<Gas className="wd-20 mn-wd-20" />}
            horizontal
          >
            <CounterInput
              value={buyFee}
              onChange={setOrderParams<NumericType>('buyFee')}
              step={0.001}
              min={0.001}
              placeholder="0.001"
              noButtons
            />
          </LabeledCard>
        </BloxSwitcher>

        <LabeledCard
          miniLabel="b"
          icon={<Slippage className="wd-20 mn-wd-20" />}
          horizontal
        >
          <CounterInput
            value={buySlippage}
            onChange={setOrderParams<NumericType>('buySlippage')}
            step={1}
            min={0}
            max={100}
            placeholder="5%"
            postfix="%"
            noButtons
          />
        </LabeledCard>

        <BloxSwitcher isBuy={false}>
          <LabeledCard
            miniLabel="s"
            icon={<Gas className="wd-20 mn-wd-20" />}
            horizontal
          >
            <CounterInput
              value={sellFee}
              onChange={setOrderParams<NumericType>('sellFee')}
              step={0.001}
              min={0.001}
              placeholder="0.001"
              noButtons
            />
          </LabeledCard>
        </BloxSwitcher>
        <LabeledCard
          miniLabel="s"
          icon={<Slippage className="wd-20 mn-wd-20" />}
          horizontal
        >
          <CounterInput
            value={sellSlippage}
            onChange={setOrderParams<NumericType>('sellSlippage')}
            step={1}
            min={0}
            max={100}
            placeholder="5%"
            postfix="%"
            noButtons
          />
        </LabeledCard>
      </div>

      <div className="d-flex flex-row gap-2 mt-2 mb-3">
        <LabeledCard
          label="common.buy"
          labelClassName="tx-10 tx-success ms-1 me-2"
          horizontal
        >
          <CounterInput
            value={activeBuySellSetting?.buy ?? buyAmount}
            onChange={setOrderParams<NumericType>('buyAmount')}
            step={0.1}
            min={0.1}
            postfix={` ${symbol}`}
            placeholder={`0.1 ${symbol}`}
          />
        </LabeledCard>
        <LabeledCard
          label="common.sell"
          labelClassName="tx-10 tx-danger ms-1 me-2"
          horizontal
        >
          <CounterInput
            value={activeBuySellSetting?.sell ?? sellAmount}
            onChange={setOrderParams<NumericType>('sellAmount')}
            step={5}
            min={0}
            max={100}
            postfix="%"
            placeholder="5%"
          />
        </LabeledCard>
      </div>
    </div>
  );
});

export default SolanaFlipMarketQuickSettings;
