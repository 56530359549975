import React from 'react';
import SolanaFlipMarketQuickSettings from '@pages/LimitOrders/SolanaFlip/components/SolanaFlipMarketQuickSettings';
import SolanaFlipButtons from '@pages/LimitOrders/SolanaFlip/components/SolanaFlipButtons';
import SolanaFlipOrders from '@pages/LimitOrders/SolanaFlip/components/SolanaFlipOrders';

const SolanaFlipMarket = () => {
  return (
    <div>
      <SolanaFlipMarketQuickSettings />
      <SolanaFlipOrders />
      <SolanaFlipButtons isPlaceholder />
      <SolanaFlipButtons />
    </div>
  )
};

export default SolanaFlipMarket;
