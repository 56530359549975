import React, { useEffect } from 'react';
import { useAnimate } from 'framer-motion';
import { randomNumber } from '@helpers/numbers';
import { useTranslation } from 'react-i18next';

export interface TabCardItem {
  value: string | number;
  label?: string | React.ReactNode;
}

interface TabsCardProps {
  list: TabCardItem[];
  active?: string | number;
  onClick?: (value: any) => void;
  className?: string;
  buttonsClassName?: string;
  tabClassName?: string;
  children?: React.ReactNode;
  noContent?: boolean;
  noAnimation?: boolean;
  disabled?: boolean;
  variant?: 'inner' | 'default';
}

export default function TabsCard(props: TabsCardProps) {
  const {
    list,
    active,
    onClick,
    className = '',
    tabClassName,
    buttonsClassName,
    noContent = false,
    noAnimation = false,
    children,
    disabled = false,
    variant: vrnt = 'default',
  } = props;

  const variants = {
    default: {
      buttonsClassName:
        buttonsClassName ??
        'card flex-row p-1 justify-content-between bg-semi-transparent-10 mb-3',
      tabClassName: tabClassName ?? 'py-2',
      indicatorClassName: 'bg-semi-transparent rounded-2 position-absolute',
      sizeMultiplier: 2,
    },
    inner: {
      buttonsClassName:
        buttonsClassName ??
        'card flex-row pd-2 justify-content-between mb-3 rounded-4-px',
      tabClassName: tabClassName ?? 'pd-y-4',
      indicatorClassName:
        'bg-semi-transparent-10 rounded-4-px position-absolute',
      sizeMultiplier: 1,
    },
  };

  const variant = variants[vrnt];

  const { t } = useTranslation();
  const number = randomNumber();
  const [scope, animate] = useAnimate();
  const activeIndex = list.findIndex(({ value }) => value === active);
  const width = 100 / list.length;

  useEffect(() => {
    animate(`#tab-indicator-${number}`, {
      left:
        activeIndex + 1
          ? `calc(${width * activeIndex}% + ${variant.sizeMultiplier * 2}px)`
          : '-250px',
      zIndex: 1,
    });
    if (noContent) return;
    list.forEach(({ value }) => {
      const isActive = value === active;
      if (
        document.querySelector(
          `.tab-element.tab-${value.toString().replace(' ', '_')}`,
        )
      ) {
        if (!noAnimation) {
          animate(
            `.tab-element.tab-${value.toString().replace(' ', '_')}`,
            { opacity: isActive ? 1 : 0, display: isActive ? 'block' : 'none' },
            { duration: 0.3, delay: isActive ? 0.2 : 0 },
          );
        } else {
          document
            .querySelector(
              `.tab-element.tab-${value.toString().replace(' ', '_')}`,
            )
            ?.setAttribute(
              'style',
              `display: ${isActive ? 'block' : 'none'}; opacity: ${isActive ? 1 : 0}`,
            );
        }
      }
    });
  }, [list, activeIndex, animate, noContent]);

  const handleClick = (value: string | number) => {
    if (disabled) return;
    onClick && onClick(value);
  };

  return (
    <div className={className} ref={scope}>
      <div className={variant.buttonsClassName}>
        <div
          className={variant.indicatorClassName}
          style={{
            width: `calc(${width}% - ${variant.sizeMultiplier * 4}px)`,
            height: `calc(100% - ${variant.sizeMultiplier * 4}px)`,
            top: `${variant.sizeMultiplier * 2}px`,
          }}
          id={`tab-indicator-${number}`}
        />
        {list.map(({ value, label }, index) => (
          <div
            className={`tab-button tab-${value.toString().replace(' ', '_').toLowerCase()} tx-14 rounded-2 tx-center cur-pointer wd-100p ${value !== active ? 'tx-muted' : ''} ${variant.tabClassName} ${disabled ? 'op-8' : ''}`}
            onClick={() => handleClick(value)}
            key={`tab-card-${value}-${index}`}
            style={{ zIndex: 2 }}
          >
            {['string', 'undefined'].includes(typeof label)
              ? t(String(label || value.toString()).toLowerCase())
              : label}
          </div>
        ))}
      </div>
      <div className="tab-content position-relative">{children}</div>
    </div>
  );
}
