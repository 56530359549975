import React from 'react';
import chevrons from '@assets/icons/chevrons.svg';
import chevronUp from '@assets/icons/chevron-up-gray.svg';
import chevronDown from '@assets/icons/chevron-down-gray.svg';

export enum SortType {
  DEFAULT = 0,
  DESC = 1,
  ASC = 2,
}

interface SorterProps {
  asc: SortType;
  onClick?: () => void;
}

const Sorter = ({asc, onClick}: SorterProps) => {
  const sortIcons = {
    [SortType.DEFAULT]: chevrons,
    [SortType.DESC]: chevronDown,
    [SortType.ASC]: chevronUp,
  };

  return (
    <img src={sortIcons[asc]} alt="sort" onClick={onClick} width={12} />
  );
};

export default Sorter;
