import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import BadgeIcon from '@components/common/BadgeIcon';
import Preloader from '@components/common/Preloader';
import useResponseHandler from '@hooks/useResponseHandler';
import LimitOrdersSummary from '@pages/LimitOrders/components/LimitOrdersSummary';
import { OrdersSummaryResponse } from '../../types/order/orders-summary.response';
import SummaryModal from '@pages/LimitOrders/components/SummaryModal';
import { useTranslation } from 'react-i18next';
import usePersistentScroll from '@hooks/usePersistentScroll';
import { ChainId } from '../../constants';

const LimitOrdersHidden = observer(() => {
  const { ordersStore, accountStore, settingsStore } = useStores();
  const { refId } = accountStore;
  const { t } = useTranslation();
  const { analyticsBot } = settingsStore;
  const { network } = ordersStore;

  const [isInit, setIsInit] = useState<boolean>(false);
  const [summary, setSummary] = useState<OrdersSummaryResponse[]>([]);

  const handleResponse = useResponseHandler();

  const [isError, setIsError] = useState<boolean>(false);
  const [selected, setSelected] = useState<OrdersSummaryResponse | null>(null);

  const flipAvailable = network === ChainId.SOLANA;

  usePersistentScroll({ key: 'hidden-tokens-page', isReady: !isError && isInit });

  const fetchSummary = (notification?: string) => {
    ordersStore
      .loadHiddenSummary()
      .then((response) => {
        setSummary(response);
        setIsInit(true);
        setSelected(null);
        if (notification) {
          handleResponse(t(notification));
        }
      })
      .catch((e) => {
        setIsInit(true);
        console.error(e);
        handleResponse(e.response);
        setIsError(true);
      });
  };

  useEffect(() => {
    if (!isInit && ordersStore.isInit) {
      fetchSummary();
    }
  }, [isInit, ordersStore.isInit]);

  usePersistentScroll({ key: 'hidden-trade-page', isReady: isInit && isInit });

  return (
    <div className="pt-3 pb-5">
      <div className="tx-28 tx-semibold">{t('trading.hidden-tokens')}</div>

      {!isError && (
        <>
          {!isInit && (
            <div className="tx-center pt-5">
              <Preloader
                inline
                iconSize={64}
                className="d-flex flex-column align-items-center"
                textClass="d-block tx-semibold tx-28 mt-4"
                iconClass="d-block"
              />
            </div>
          )}
          {isInit && !summary.length && (
            <div className="d-flex flex-column align-items-center justify-content-center tx-center pt-5">
              <BadgeIcon badgeSize={85} className="tx-38 mb-2">
                📊
              </BadgeIcon>
              <div className="tx-semibold tx-22">
                {t('trading.no-hidden-tokens-yet')}
              </div>
            </div>
          )}
          {isInit && !!summary.length && (
            <div className="mt-4">
              {summary.map((item) => (
                <LimitOrdersSummary
                  key={`limit-orders-summary-${item.pairAddress}-${item.openOrders}`}
                  data={item}
                  toggleSelected={() => setSelected(item)}
                  chartLink={settingsStore.getChartLink(
                    item.blockchain,
                    item.pairAddress,
                  )}
                  network={network}
                  analyticsLink={`https://t.me/${analyticsBot}?start=${item.pairAddress}`}
                  flipAvailable={flipAvailable}
                  backToHidden
                />
              ))}
            </div>
          )}
        </>
      )}

      <SummaryModal
        onClose={() => setSelected(null)}
        item={selected}
        onCancel={() => fetchSummary('order.orders-cancelled')}
        onHide={() => fetchSummary('order.token-visible-now')}
        refId={refId ?? undefined}
        isHidden
      />
    </div>
  );
});

export default LimitOrdersHidden;
