import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ColorVariant } from '../../types';

interface LabelValueCardProps {
  miniLabel?: string;
  miniLabelVariant?: ColorVariant;
  label?: string;
  className?: string;
  contentClassName?: string;
  labelClassName?: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  horizontal?: boolean;
}

const LabeledCard = (props: LabelValueCardProps) => {
  const {
    miniLabel,
    miniLabelVariant = miniLabel === 's' ? 'danger' : 'success',
    label,
    className,
    labelClassName = 'tx-muted tx-10',
    contentClassName = 'd-flex align-items-center',
    children,
    horizontal = false,
    icon,
  } = props;
  const { t } = useTranslation();
  return (
    <div
      className={cn([
        'wd-100p',
        'card p-1',
        'bg-semi-transparent-10 border border-semi-transparent',
        'd-flex',
        horizontal
          ? 'flex-row align-items-center justify-content-between'
          : 'flex-column',
        className,
      ])}
    >
      <div className={labelClassName}>
        {(icon || !!miniLabel) && (
          <span className={`tx-${miniLabelVariant} me-1 tx-uppercase`}>{icon || miniLabel}</span>
        )}
        {!!label && t(label.toLowerCase())}
      </div>
      <div className={contentClassName}>
        {children}
      </div>
    </div>
  );
};

export default LabeledCard;
