interface HandleScrollToPixelsOptions {
  offset: number;
  callback?: () => void;
  direction?: 'up' | 'down';
  once?: boolean;
  delay?: number;
}

export const scrollToPixels = (offset: number) => {
  const rootElement = document.querySelector('.full-page')!;

  rootElement.scrollTo({ top: offset });
};

export const handleScrollToPixels = ({
  offset = 0,
  callback = () => null,
  direction = 'down',
  once = false,
  delay = 0,
}: HandleScrollToPixelsOptions) => {
  const rootElement = document.querySelector('.full-page')!;

  const clearEventListener = () => {
    rootElement.removeEventListener('scroll', scrollHander);
  };

  function scrollHander() {
    const currentOffset = rootElement.scrollTop;
    const condition = direction === 'down'
      ? currentOffset > offset
      : currentOffset < offset;

    if (condition) {
      callback();

      if (once) {
        clearEventListener();
      }
    }
  }

  setTimeout(() => {
    rootElement.addEventListener('scroll', scrollHander);
  }, delay);

  return clearEventListener;
};
