import { action, computed, makeAutoObservable, observable, reaction, runInAction } from 'mobx';
import api, { Paged } from '@helpers/api';
import { ResearchPairResponse } from 'types/research/research-pair.response';
import { TokenBalanceResponse } from 'types/order/token-balance.response';
import { GasResponse } from 'types/common.types';
import { SocketStore } from './socket-store';
import { AccountStore } from './account-store';
import { OrdersStore } from './orders-store';
import { AxiosError } from 'axios';
import { ChainId, PageRoutes } from '../constants';
import { WalletType } from 'types/enums';
import { OrderResponse } from 'types/order/order.response';
import { OrderStatus, OrderType } from 'types/order/order.enum';
import BigNumber from 'bignumber.js';
import { BlockchainStore } from '@stores/blockchain-store';
import { PriceUpdateResponse } from 'types/socket/price-update.types';
import { getUpdatedTokenBalance, getUpdatedTokenDetails } from '@helpers/priceUpdate';
import { PortfolioToken } from 'types';
import { OrdersSummaryResponse } from 'types/order/orders-summary.response';

export type TokenParams = Record<'chain' | 'address' | 'pairId', string>;
export type TradeParams = Record<'chain' | 'address' | 'id', string>;

export enum TokenEvents {
  SUBSCRIBE_PRICE_UPDATE = 'SUBSCRIBE_PRICE_UPDATE',
  UNSUBSCRIBE_PRICE_UPDATE = 'UNSUBSCRIBE_PRICE_UPDATE',
}

export class TokenError extends Error {
  navigate?: string | number;

  constructor({
                message,
                navigate,
              }: {
    message: string;
    navigate?: string | -1;
    [key: string]: any;
  }) {
    super(message);
    this.message = message;
    this.navigate = navigate;
  }
}

export class TokenStore {
  private _socketStore: SocketStore;
  private _accountStore: AccountStore;
  private _blockchainStore: BlockchainStore;
  private _ordersStore: OrdersStore;

  @observable
  private _error: TokenError | null;

  @observable
  private _tokenDetails: ResearchPairResponse | null;

  @observable
  private _tokenBalance: TokenBalanceResponse | null;

  @observable
  private _tokenParams: Partial<TokenParams> | null;

  @observable
  private _gas: GasResponse | null;

  @observable
  private _orders: OrderResponse[] | null;

  @computed
  get socket() {
    return this._socketStore.socket;
  }

  @computed
  get error() {
    return this._error;
  }

  /** TokenParams */
  @computed
  get chain() {
    return this._tokenParams?.chain;
  }

  @computed
  get address() {
    return this._tokenParams?.address;
  }

  @computed
  get pairId() {
    return this._tokenParams?.pairId;
  }

  @computed
  get balance() {
    return this._ordersStore.balance;
  }

  @computed
  get networkChecked() {
    return this.chain === this._ordersStore.network;
  }

  @computed
  get tokenDetails() {
    return this._tokenDetails;
  }

  @computed
  get tokenBalance() {
    return this._tokenBalance;
  }

  @computed
  get gas() {
    return this._gas;
  }

  @computed
  get orders() {
    return this._orders;
  }

  @computed
  get isExecuting() {
    return this.orders?.some(
      (order) =>
        [OrderType.MARKET_BUY, OrderType.MARKET_SELL].includes(order.type) &&
        order.status === OrderStatus.EXECUTING,
    );
  }

  @computed
  get nativeTokenPrice() {
    return new BigNumber(this.tokenDetails?.usdPrice || 0)
      .div(this.tokenDetails?.priceInBaseToken || 0)
      .toNumber();
  }

  @computed
  get nativeTokenPriceUsd() {
    return !this._ordersStore.balance
      ? 0
      : new BigNumber(this._ordersStore.balance.balanceInUsd)
        .div(this._ordersStore.balance.balance)
        .toNumber();
  }

  @computed
  get tokenUsdPrice() {
    if (
      this.tokenBalance?.tokenBalanceInUsd &&
      this.tokenBalance?.tokenBalanceInUsd !== '0'
    ) {
      return new BigNumber(this.tokenBalance.tokenBalanceInUsd)
        .div(this.tokenBalance.tokenBalance)
        .toString();
    }
    return this.tokenDetails?.usdPrice || '0';
  }

  @computed
  get tokenBalanceFormatted() {
    return !this.tokenBalance || !this.tokenDetails
      ? null
      : {
        balance: this.tokenBalance.tokenBalance,
        balanceInUsd: this.tokenBalance.tokenBalanceInUsd,
        token: this.getTokenParamFromDetails('symbol', this.tokenDetails),
      };
  }

  @computed
  get limitData() {
    return {
      price: this.tokenDetails?.usdPrice,
      priceBase: this.tokenDetails?.priceInBaseToken,
      mCap: this.tokenDetails?.mcap,
      liquidity: this.tokenDetails?.liquidity?.usd,
    };
  }

  constructor(
    socketStore: SocketStore,
    accountStore: AccountStore,
    ordersStore: OrdersStore,
    blockchainStore: BlockchainStore,
  ) {
    this._socketStore = socketStore;
    this._accountStore = accountStore;
    this._ordersStore = ordersStore;
    this._blockchainStore = blockchainStore;
    makeAutoObservable(this);

    reaction(
      () =>
        this._accountStore.wallets.length &&
        !!this.chain &&
        this.chain !== this._ordersStore.network,
      (rule) => {
        if (rule) {
          this._accountStore.setNetwork(this.chain as ChainId);
          this.getBlockchainGas();
        }
      },
    );

    reaction(
      () => {
        return JSON.stringify([
          this.address,
          this._ordersStore.isInit,
          this.chain,
        ]);
      },
      () => {
        if (
          [this.address, this._ordersStore.isInit, this.chain].every((c) => !!c)
        ) {
          this.getPairDetails();
          this.getBlockchainGas();
          this._ordersStore.loadBalance();
        }
      },
    );

    reaction(
      () => this._ordersStore.isInit && this.pairId,
      (rule) => {
        if (rule) {
          this.getOrders(undefined, undefined, this.pairId);
        }
      },
    );

    reaction(
      () => socketStore.isOnline,
      (isOnline) => {
        if (!isOnline) {
          this.socket.disconnect();
          this.socket.connect();
          this.subscribePrice();
        }
      },
    );

    reaction(
      () => this.tokenDetails && this.chain && this._accountStore.currentWallet,
      (rule) => {
        if (rule) {
          this.getTokenBalance();
        }
      },
    );
  }

  @action.bound
  setRouteParams(params: TokenParams) {
    this._tokenParams = params;
  }

  @action.bound
  setTokenDetails(token: ResearchPairResponse) {
    this._tokenDetails = token;
  }

  @action.bound
  async errorCatcher(response: AxiosError | TokenError) {
    console.log('errorCatcher', response);
    if (response instanceof AxiosError && response.response?.status === 401) {
      this._accountStore.logout();
    }
    if (response instanceof AxiosError && response.response?.data) {
      console.error(response.response.data);
    }
    if (response instanceof TokenError) {
      this._error = response;
    } else {
      console.error(response);
      return response.response;
    }
  }

  @action.bound
  async getBlockchainGas() {
    if (this._accountStore.currentWallet?.type === WalletType.EVM)
      if (
        this.chain &&
        ![ChainId.TRON, ChainId.TON, ChainId.SOLANA].includes(
          this.chain as ChainId,
        )
      ) {
        this._blockchainStore.getBlockchainGas(this.chain).then((gas) => {
          this._gas = gas;
        });
      }
  }

  @action.bound
  async getOrders(
    filter: string = 'ACTIVE',
    type: OrderType[] = Object.values(OrderType),
    pairId?: string,
  ) {
    const types = type ? type.map((v) => `type=${v}`).join('&') : undefined;

    return api<Paged<OrderResponse[]>>({
      method: 'get',
      path: `/order${types ? '?' + types : ''}`,
      data: {
        walletId: this._accountStore.currentWallet?.id,
        pairId: pairId || this.pairId,
        filter,
      },
    })
      .then((response) => {
        const result = response?.content ?? [];
        this._orders = result;
        return result;
      })
      .catch((response) =>
        this.errorCatcher(
          new TokenError({
            ...(response.response?.data && { message: 'errors.network-error' }),
            navigate: PageRoutes.LIMIT_ORDERS,
          }),
        ),
      );
  }

  getTokenParamFromDetails(
    key: 'address' | 'name' | 'symbol',
    details: ResearchPairResponse | null,
  ): string;
  getTokenParamFromDetails(
    key: 'address' | 'name' | 'symbol',
    details: OrdersSummaryResponse | null,
  ): string;
  getTokenParamFromDetails(
    key: 'address' | 'name' | 'symbol',
    details: PortfolioToken | null,
  ): string;
  getTokenParamFromDetails(
    key: string,
    details:
      | ResearchPairResponse
      | OrdersSummaryResponse
      | PortfolioToken
      | null,
  ) {
    if (details === null) return '';

    const address =
      (details as ResearchPairResponse)?.quoteToken?.address ||
      (details as OrdersSummaryResponse)?.tokenAddress ||
      (details as PortfolioToken)?.address;

    const name =
      (details as ResearchPairResponse)?.quoteToken?.name ||
      (details as OrdersSummaryResponse)?.tokenName ||
      (details as PortfolioToken)?.name;

    const symbol =
      (details as ResearchPairResponse)?.quoteToken?.symbol ||
      (details as OrdersSummaryResponse)?.tokenSymbol ||
      (details as PortfolioToken)?.symbol;

    return { address, name, symbol }[key];
  }

  @action.bound
  async getTokenBalance() {
    return api<TokenBalanceResponse>({
      method: 'get',
      path: `/balance/token`,
      data: {
        walletId: this._accountStore.currentWallet?.id,
        tokenAddress: this.getTokenParamFromDetails(
          'address',
          this.tokenDetails,
        ),
        blockchain: this.chain,
      },
    })
      .then((response) => {
        this._tokenBalance = response;

        return response;
      })
      .catch((response) => {
        this.errorCatcher(
          new TokenError({
            ...(response.response?.data && { message: 'errors.network-error' }),
          }),
        );
      });
  }

  @action.bound
  async getPairDetails() {
    return api<ResearchPairResponse[]>({
      method: 'get',
      path: `/pair/search`,
      data: {
        q: this.address,
      },
    })
      .then((response) => {
        if (response && !response.length)
          throw new TokenError({
            message: 'Get pair details error',
            navigate: PageRoutes.LIMIT_ORDERS,
          });
        this._tokenDetails = response[0];
        this.subscribePrice();
      })
      .catch((response) => this.errorCatcher(response));
  }

  @action.bound
  async subscribePrice() {
    this.socket.on(
      TokenEvents.SUBSCRIBE_PRICE_UPDATE,
      (data: PriceUpdateResponse) => {
        if (
          this.tokenDetails?.pairAddress.toLowerCase() ===
          data.pairAddress.toLowerCase()
        ) {
          runInAction(() => {
            if (this.tokenDetails)
              this._tokenDetails = getUpdatedTokenDetails(
                this.tokenDetails,
                data,
              );

            if (this.tokenBalance)
              this._tokenBalance = getUpdatedTokenBalance(
                this.tokenBalance,
                data,
              );
          });
        }
      },
    );

    this.socket.emit(TokenEvents.SUBSCRIBE_PRICE_UPDATE, {
      blockchain: this.chain,
      pairAddresses: [this.address],
    });
  }

  @action.bound
  async unsubscribePrice() {
    this.socket?.emit(TokenEvents.UNSUBSCRIBE_PRICE_UPDATE);
  }

  @action.bound
  async reset() {
    this.unsubscribePrice();
    this._error = null;
    this._orders = null;
    this._tokenParams = null;
    this._gas = null;
    this._tokenDetails = null;
    this._tokenBalance = null;
  }
}
