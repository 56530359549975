import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import useNotification from '@hooks/useNotification';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import { WalletType } from 'types/enums';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import Vibrutton from '@components/common/Vibrutton';
import TabsCard from '@components/common/TabsCard';
import solana from '@assets/images/solana.svg';
import NumericInput from '@components/common/NumericInput';
import FormattedNumber from '@components/common/FormattedNumber';
import { NumericType } from '../../../../types';
import WalletSelection from '@components/WalletSelection';
import refresh from '@assets/icons/refresh-gray.svg';
import BigNumber from 'bignumber.js';

const SolanaFlipWrapperModal = observer(() => {
  const { flipStore } = useStores();
  const { balance, isPro } = flipStore;
  const notify = useNotification();
  const { t } = useTranslation();
  const [w] = useAmplitude();

  const [show, setShow] = useState<boolean>(false);
  const [isWrap, setIsWrap] = useState<0 | 1>(1);
  const [amount, setAmount] = useState<NumericType>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClose = () => {
    setShow(false);
    setIsLoading(false);
    setAmount('');
    setIsWrap(1);
  };

  const handleMax = () => {
    if (!balance) return;

    if (isWrap) {
      setAmount(balance.balance);
    } else {
      setAmount(balance.balanceWrapped);
    }
  };

  const handleSwap = () => {
    if (!amount) return;

    setIsLoading(true);

    flipStore
      .swapBalance(new BigNumber(amount).toString(), isWrap ? 'wrap' : 'unwrap')
      .then((response) => {
        if (response.success) {
          notify(t(isWrap ? 'trading.sol-unwrapped' : 'trading.sol-wrapped', { amount, currency: isWrap ? 'SOL' : 'WSOL' }));
          handleClose();
        }  else {
          setIsLoading(false);
          notify(response.message, { type: 'danger' });
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleShow = () => {
    if (!isPro) return;

    w(() => setShow(true), AmplitudeEvent.ORDER_SETTINGS_CLICKED)();
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="modal-header-new" />
        <Modal.Body className="pt-0 d-flex flex-column justify-content-between">
          <div className="d-flex justify-content-center pb-4">
            <img src={solana} alt="Solana" className="wd-45 rounded-circle border border-semi-transparent" />
          </div>
          <TabsCard
            className="wd-100p"
            list={[{ value: 1, label: 'Wrap' }, { value: 0, label: 'Unwrap' }]}
            active={isWrap}
            onClick={setIsWrap}
            tabClassName="tx-capitalize py-2"
          />
          <div className="d-flex align-items-center justify-content-start tx-13 mb-1">
            <div className="tx-muted me-1">{t('common.balance')}:</div>
            <FormattedNumber
              value={balance?.balance}
              decimals={4}
              subZeros
              postfix="SOL"
            />
            <span className="mx-1">|</span>
            <FormattedNumber
              value={balance?.balanceWrapped}
              postfix="WSOL"
              floor
              subZeros
            />
          </div>

          <div className="card px-3 py-2 align-items-center flex-row bg-semi-transparent-10 mb-3">
            <NumericInput
              value={amount}
              onChange={setAmount}
              postfix={isWrap ? ' SOL' : ' WSOL'}
              placeholder="0"
              className="form-control bg-transparent ps-0 py-0"
              disabled={isLoading}
            />
            {!isWrap && (
              <Vibrutton
                className="btn btn-link tx-primary bg-transparent border-0 p-0 text-decoration-none"
                onClick={handleMax}
                disabled={isLoading}
              >
                Max
              </Vibrutton>
            )}
          </div>

          <div>
            <Vibrutton
              className="btn btn-primary wd-100p py-2"
              onClick={handleSwap}
              disabled={isLoading}
            >
              {isWrap ? 'Wrap' : 'Unwrap'}
            </Vibrutton>

            <Vibrutton
              className="btn btn-semi-10 wd-100p py-2 mt-2"
              onClick={() => setShow(false)}
              disabled={isLoading}
            >
              {t('common.close')}
            </Vibrutton>
          </div>
        </Modal.Body>
      </Modal>

      <div className="d-flex align-items-center">
        <WalletSelection filterTypes={[WalletType.SOL]}>
          <div className="d-flex wd-20 ht-20 rounded-30 border border-semi-transparent">
            <img src={solana} alt="solana" width={20} />
          </div>
        </WalletSelection>
        <Vibrutton
          className="btn btn-link tx-white bg-transparent border-0 d-flex justify-content-center align-items-center cur-pointer tx-12 p-0 text-decoration-none"
          onClick={handleShow}
        >
          <FormattedNumber
            value={balance?.balance}
            postfix="SOL"
            className="ms-2"
          />
          {isPro && (
            <>
              <span className="mx-1">|</span>
              <FormattedNumber
                value={balance?.balanceWrapped}
                postfix="WSOL"
                floor
                subZeros
              />
              <img src={refresh} alt="Wrap Solana" className="mn-wd-12 mx-wd-12 ms-1" />
            </>
          )}
        </Vibrutton>
      </div>
    </>
  );
});

export default SolanaFlipWrapperModal;
