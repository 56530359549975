import React, { useEffect, useState } from 'react';
import Vibrutton from '@components/common/Vibrutton';
import cn from 'classnames';
import { ColorVariant } from '../../types';
import { useTranslation } from 'react-i18next';

type Value = string | number;

export interface ButtonItem {
  value: Value | null;
  label?: string | React.ReactNode;
  disabled?: boolean;
  variant?: ColorVariant;
  className?: string;
}

interface ButtonGroupProps {
  items: ButtonItem[];
  onChange?: (value: Value, index: number) => void;
  activeIndex?: number;
  className?: string;
  postfix?: string;
}

const ButtonGroup = (props: ButtonGroupProps) => {
  const { t } = useTranslation();
  const { items, onChange, activeIndex, className = 'gap-2', postfix } = props;
  const [active, setActive] = useState(activeIndex || 0);

  useEffect(() => {
    if (activeIndex !== -1) setActive(activeIndex || 0);
  }, [activeIndex]);

  const handleClick = (value: Value, index: number) => {
    setActive(index);
    if (onChange) {
      onChange(value, index);
    }
  };

  return (
    <div className={cn(['button-group', className])}>
      {items
        .filter(({ value }) => value)
        .map(
          ({ variant = 'primary', disabled, value, label, ...item }, index) => (
            <Vibrutton
              key={index}
              protectFromDrag
              className={cn([
                'button-group-item',
                'btn btn-sm wd-100p',
                `btn-${variant === 'primary' && active !== index ? 'secondary' : variant}`,
                `bg-semi-${variant === 'primary' && active !== index ? 'secondary' : variant}-10`,
                active === index && 'active',
                disabled && 'disabled',
                item.className,
              ])}
              onClick={() => !disabled && handleClick(value!, index)}
            >
              {t((label || value!).toString().toLowerCase())}
              {postfix}
            </Vibrutton>
          ),
        )}
    </div>
  );
};

export default ButtonGroup;
