import React, { useMemo } from 'react';
import { NumericType } from '../../types';
import NumericInput from '@components/common/NumericInput';
import plus from '@assets/icons/plus-square.svg';
import minus from '@assets/icons/minus-square.svg';
import BigNumber from 'bignumber.js';

interface NumericInputProps {
  placeholder?: string;
  disabled?: boolean;
  onChange: (_v: NumericType) => void;
  isInvalid?: boolean;
  value: NumericType;
  className?: string;
  noDecimal?: boolean;
  prefix?: string;
  postfix?: string;
  step?: number;
  min?: number;
  max?: number;
  noButtons?: boolean;
}

const CounterInput = (props: NumericInputProps) => {
  const { step = 1, min, max, isInvalid: isInvalidProp, noButtons = false, ...rest } = props;

  const handleStep = (multiplier = 1) => {
    if (props.value === null || props.value === '') return (step * multiplier);
    let newValue = new BigNumber(props.value).plus(step * multiplier);
    if (min != undefined && newValue.isLessThan(min)) {
      newValue = new BigNumber(min);
    }
    if (max != undefined && newValue.isGreaterThan(max)) {
      newValue = new BigNumber(max);
    }
    props.onChange(newValue.toString());
  };

  const isInvalid = useMemo(() => {
    if (props.value === null || props.value === '') return isInvalidProp;
    const value = new BigNumber(props.value);
    if (min != undefined && value.isLessThan(min)) return true;
    if (max != undefined && value.isGreaterThan(max)) return true;
    return isInvalidProp;
  }, [props.value, isInvalidProp, min, max]);

  return (
    <div className="d-flex align-items-center">
      <NumericInput
        className="bg-transparent p-0 form-control"
        isInvalid={isInvalid}
        {...rest}
      />
      {!noButtons && (
        <div className="d-flex gap-1">
          <button
            type="button"
            className="btn btn-secondary p-0 bg-transparent mn-wd-20 mx-wd-20 mx-ht-20 ht-20 mn-ht-20 pos-relative"
            onClick={() => handleStep(-1)}
          >
            <img src={minus} alt="minus" className="mn-wd-20 mx-wd-20 absolute-item pos-absolute" />
          </button>
          <button
            type="button"
            className="btn btn-secondary p-0 bg-transparent mn-wd-20 mx-wd-20 mx-ht-20 ht-20 mn-ht-20 pos-relative"
            onClick={() => handleStep()}
          >
            <img src={plus} alt="plus" className="mn-wd-20 mx-wd-20 absolute-item pos-absolute" />
          </button>
        </div>
      )}
    </div>
  );
};

export default CounterInput;
