export enum OrderTrigger {
  PRICE_IN_USD = 'PRICE_IN_USD',
  PRICE_IN_BASE_TOKENS = 'PRICE_IN_BASE_TOKENS',
  MCAP = 'MCAP',
}

export enum OrderStatus {
  OPEN = 'OPEN',
  EXECUTING = 'EXECUTING',
  ESTIMATED = 'ESTIMATED',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED',
  WAITING = 'WAITING',
}

export enum OrderType {
  MARKET_BUY = 'MARKET_BUY',
  MARKET_SELL = 'MARKET_SELL',
  LIMIT_BUY = 'LIMIT_BUY',
  LIMIT_SELL = 'LIMIT_SELL',
  STOP_LOSS = 'STOP_LOSS',
  STOP_BUY = 'STOP_BUY',
}

export enum OrderTransactionType {
  BUY = 'BUY',
  SELL = 'SELL',
  APPROVE = 'APPROVE',
}

export enum OrderTransactionStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  LOST = 'LOST',
}

export enum OrderVariant {
  MARKET = 'MARKET',
  LIMIT = 'LIMIT',
  STOP_LOSS = 'STOP_LOSS',
  ALPHA = 'ALPHA'
}

export enum AlphaOrderLastLevelReachStrategy {
  EXECUTE_ORDER = 'EXECUTE_ORDER',
  EXECUTION_TRAILING = 'EXECUTION_TRAILING',
  EXECUTE_DEFAULT = 'EXECUTE_DEFAULT',
}


export const ALL_ORDER_FILTERS = Object.values(OrderType);