import React, { useCallback, useMemo } from 'react';
import FormattedNumber from '@components/common/FormattedNumber';
import BigNumber from 'bignumber.js';
import useOrderData from '@hooks/useOrderData';
import checkGreen from '@assets/icons/checkmark-green.svg';
import checkRed from '@assets/icons/checkmark-red.svg';
import warning from '@assets/icons/warning-dark.svg';
import clock from '@assets/icons/clock.svg';
import { OrderResponse } from '../../../../types/order/order.response';
import { LimitData } from '../../../../types/common.types';
import { roundTokenNumber } from '@helpers/calculations';

interface SolanaFlipOrderItemProps {
  item: OrderResponse;
  isLast: boolean;
  onClick: () => void;
  limitData: LimitData;
  nativePrice: number;
  isMcap: boolean;
}

const SolanaFlipOrderItem = ({
  item,
  onClick,
  limitData,
  nativePrice,
  isMcap,
}: SolanaFlipOrderItemProps) => {
  const { valueIn, valueOut } = item;

  const {
    isBuy,
    isError,
    isExecuting,
    isEstimated,
    dateShort,
    isCompleted,
    usdPrice,
    executionMcap: mCap,
    metadata,
  } = useOrderData(item, limitData, nativePrice);

  const formatDecimals = useCallback((value: BigNumber) => {
    const abs = value.abs();
    if (abs.isGreaterThan(100)) {
      return 0;
    }
    return abs.isLessThan(0.000001) ? 10 : 6;
  }, []);

  const trClassName = useMemo(() => {
    if (isError) {
      return 'bg-danger tx-black op-4';
    }
    const color = isBuy ? 'success' : 'danger';
    return `bg-semi-${color}-10 tx-${color}`;
  }, [isBuy, isError]);

  const value = useMemo(() => {
    return isBuy ? valueIn : valueOut;
  }, [isBuy, valueIn, valueOut]);

  const amount = useMemo(() => {
    return new BigNumber(!isBuy ? valueIn : valueOut);
  }, [isBuy, valueIn, valueOut]);

  const tokensValue = useMemo(
    () => (isBuy && isExecuting ? null : roundTokenNumber(amount.valueOf())),
    [amount],
  );

  const icon = useMemo(() => {
    if (isError) {
      return warning;
    }
    if (isExecuting || isEstimated) {
      return clock;
    }
    return isBuy ? checkGreen : checkRed;
  }, [isBuy, isError, isExecuting, isEstimated]);

  return (
    <tr className={`cur-pointer tx-12 ${trClassName}`} onClick={onClick}>
      <td>
        {isError && '–'}
        {!isError && (
          <FormattedNumber
            value={metadata?.sellSolValue || value}
            decimals={formatDecimals(
              new BigNumber(metadata?.sellSolValue || value),
            )}
            useFormat={new BigNumber(
              metadata?.sellSolValue || value || 0,
            ).isGreaterThan(99000)}
            limitPlaceholderSize
            subZeros
          />
        )}
      </td>
      <td>{dateShort}</td>
      <td>
        {isError && '–'}
        {!isError && (
          <FormattedNumber
            value={tokensValue}
            decimals={formatDecimals(amount)}
            useFormat={amount.isGreaterThan(99000)}
            limitPlaceholderSize
            subZeros
          />
        )}
      </td>
      <td>
        {isError && '–'}
        {!isError && !isMcap && (
          <FormattedNumber
            value={usdPrice.isNaN() ? null : usdPrice.toNumber()}
            suffix="$"
            decimals={usdPrice.isLessThan(1) ? 4 : 0}
            limitPlaceholderSize
            subZeros
          />
        )}
        {!isError && isCompleted && isMcap && !mCap && <div>–</div>}
        {!isError && isMcap && !!mCap && (
          <FormattedNumber
            value={
              isExecuting || (isEstimated && new BigNumber(mCap).isNaN())
                ? null
                : new BigNumber(mCap || 0).toNumber()
            }
            suffix="$"
            decimals={new BigNumber(mCap || 0).isGreaterThan(99000) ? 2 : 0}
            useFormat={new BigNumber(mCap || 0).isGreaterThan(99000)}
            limitPlaceholderSize
          />
        )}
      </td>
      <td>
        <img src={icon} alt="status" width={16} />
      </td>
    </tr>
  );
};

export default SolanaFlipOrderItem;
