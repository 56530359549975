import { action, autorun, computed, makeAutoObservable, observable } from 'mobx';
import api, { ApiProps, apiRaw } from '@helpers/api';
import { clearAuthTokens } from 'axios-jwt';
import {
  ApiConnectWallet,
  ApiSnipeSettings,
  IAsset,
  ISnipeSettings,
  Mnemonic,
  PositionModel,
  UpdatePinParams,
  WalletBalance,
} from '../types';
import { ChainId, zeroAddress } from '../constants';
import { chainToWallet } from '@helpers/chains';
import { WalletResponse } from '../types/wallet/wallet.response';
import { ReferralProfileResponse } from '../types/referral/referral-profile.response';
import { WalletType } from '../types/enums';
import { ExportWalletResponse } from '../types/wallet/export-wallet.response';
import { EstimateBridgeRouteRequest } from '../types/wallet/estimate-bridge-route.request';
import { EstimateBridgeRouteResponse } from '../types/wallet/estimate-bridge-route.response';
import { BridgeTokensRequest } from '../types/wallet/bridge-tokens.request';
import { BridgeTokensResponse } from '../types/wallet/bridge-tokens.response';
import { AxiosError } from 'axios';
import { BridgeRouteResponse } from '../types/wallet/bridge-routes.response';
import { AuthStore } from './auth-store';
import { getLocalStorageObject } from '@helpers/localStorage';

export class AccountStore {
  private _authStore: AuthStore;

  @observable
  private _address: string | null = null;

  @observable
  private _redirectTo: string | null = null;

  @observable
  private _token: string | null = null;

  @observable
  private _pinEnabled: boolean | null = null;

  @observable
  private _wallets: WalletResponse[] = [];

  @observable
  private _currentWallet: WalletResponse | null = null;

  @observable
  private _balance: WalletBalance | null = null;

  @observable
  private _network: ChainId =
    (localStorage.getItem('_network') as ChainId) ?? ChainId.ETHER;

  @observable
  private _connectWallet: ApiConnectWallet | null = null;

  @observable
  private _snipeSettings: ApiSnipeSettings | null = null;

  @observable
  private _isLoggedIn = false;

  @observable
  private _checkDone = 0;

  @observable
  private _isAppInitialized = false;

  @observable
  private _mnemonic = '';

  @observable
  private _selectedWalletGroup: WalletType | 'all' | null = 'all';

  @action.bound
  public loadAccountInfo() {
    if (!this.isLoggedIn) {
      return;
    }

    if (!this._wallets.length) {
      this.loadUser();
    }
  }

  @observable
  private _refId: string | null = null;

  @observable
  private _refData: ReferralProfileResponse | null = null;

  constructor(authStore: AuthStore) {
    this._authStore = authStore;

    makeAutoObservable(this);

    // @TODO: Оставил на всякий случай, раскомментить если будет баг с переходом из ботовой ссылки
    // autorun(() => {
    //   if (
    //     this.currentWalletType &&
    //     chainToWallet(this.network) !== this.currentWalletType
    //   ) {
    //     const changingWallet = this.wallets.find(
    //       ({ type }) => type === chainToWallet(this.network),
    //     );

    //     if (changingWallet) this.setCurrentWallet(changingWallet);
    //   }
    // });

    autorun(() => {
      const pinEnabled = localStorage.getItem('pinEnabled');
      pinEnabled && this.setPinEnabled(pinEnabled);

      if (this._authStore.accessToken) {
        this._isLoggedIn = true;
        this.loadAccountInfo();
      }
    });
  }

  @action.bound
  logout(force?: boolean) {
    this.resetStore(!force);
  }

  @action.bound
  setAddress(value: string | null) {
    this._address = value;
  }

  @computed
  get redirectTo() {
    return this._redirectTo;
  }

  @action.bound
  setRedirectTo(value: string | null) {
    this._redirectTo = value;
  }

  @computed
  get address() {
    return this.currentWallet?.address || this._address || zeroAddress;
  }

  @computed
  get isEvm() {
    return this.currentWallet?.type === WalletType.EVM;
  }

  @computed
  get isSolana() {
    return this.currentWallet?.type === WalletType.SOL;
  }

  @computed
  get isTon() {
    return this.currentWallet?.type === WalletType.TON;
  }

  @computed
  get isTron() {
    return this.currentWallet?.type === WalletType.TRON;
  }

  @computed
  get isSui() {
    return this.currentWallet?.type === WalletType.SUI;
  }

  @computed
  get currentWalletType() {
    return this.currentWallet?.type;
  }

  @computed
  get initData() {
    return this._authStore.initData;
  }

  @computed
  get isBot() {
    return !!this.initData;
  }

  @computed
  get token() {
    return this._token;
  }

  @computed
  get lockedToken() {
    return this._authStore.refreshToken;
  }

  @computed
  get isLoggedIn() {
    return this._isLoggedIn;
  }

  @computed
  get wallets() {
    return this._wallets;
  }

  @computed
  get existingWalletTypes() {
    const order = [
      WalletType.EVM,
      WalletType.SUI,
      WalletType.SOL,
      WalletType.TRON,
      WalletType.TON,
    ];
    return new Set(
      this._wallets
        .map(({ type }) => type)
        .sort((a, b) => {
          return order.indexOf(a) - order.indexOf(b);
        }),
    );
  }

  @computed
  get currentWallet() {
    return this._currentWallet;
  }

  @computed
  get walletsByType() {
    return this._wallets.filter((w) => w.type === this.currentWallet?.type);
  }

  @action.bound
  setCurrentWallet(value: WalletResponse | null) {
    this._currentWallet = value;
    if (value) {
      localStorage.setItem('currentWallet', value.id);
    } else {
      localStorage.removeItem('currentWallet');
    }
  }

  @action.bound
  setCurrentWalletById(value: string) {
    const found = this._wallets.find((w) => w.id === value);
    if (found) {
      this.setCurrentWallet(found);
    }
  }

  @computed
  get balance() {
    return this._balance;
  }

  @computed
  get checkDone() {
    return this._checkDone;
  }

  @computed
  get isAppInitialized() {
    return this._isAppInitialized;
  }

  @action.bound
  setAppInitialized(value: boolean) {
    this._isAppInitialized = value;
  }

  @computed
  get mnemonic() {
    return this._mnemonic;
  }

  @action.bound
  resetMnemonic() {
    this._mnemonic = '';
  }

  @computed
  get connectWalletInfo() {
    return this._connectWallet;
  }

  @computed
  get snipeSettings() {
    return this._snipeSettings;
  }

  @computed
  get selectedWalletGroup() {
    return this._selectedWalletGroup;
  }

  @computed
  get network() {
    if (this.isTon) {
      return ChainId.TON;
    }
    if (this.isSolana) {
      return ChainId.SOLANA;
    }
    if (this.isTron) {
      return ChainId.TRON;
    }
    if (this.isSui) {
      return ChainId.SUI;
    }
    return this._network;
  }

  @computed
  get apiAuthProps() {
    return (path: string, dataExt: Record<string, any> = {}): ApiProps =>
      this.isBot
        ? {
          method: 'post',
          path: `/bot${path}`,
          data: { ...dataExt, initData: this.initData },
        }
        : {
          method: 'post',
          path: `/user${path}`,
          data: { ...dataExt, refreshToken: this.lockedToken },
        };
  }

  @computed
  get refData() {
    return this._refData;
  }

  @computed
  get refId() {
    return this._refId;
  }

  @action.bound
  setNetwork(value: ChainId, r?: boolean) {
    const chainWallet = chainToWallet(value);
    if (!r && this._currentWallet?.type !== chainWallet) {
      const prevWalletId = (getLocalStorageObject('lastWallet') ?? {})[value];

      const found = this._wallets.find((w) =>
        prevWalletId ? w.id === prevWalletId : w.type === chainToWallet(value),
      );

      if (found) {
        this.setCurrentWallet(found);
      }
    }
    this._network = value;
    localStorage.setItem('_network', value);
  }

  @action.bound
  setSelectedWalletGroup(value: WalletType | 'all') {
    this._selectedWalletGroup = value;
  }

  @action.bound
  async loadUser() {
    if (!this.isLoggedIn) return;

    return apiRaw<WalletResponse[]>({ method: 'get', path: '/wallet' })
      .then((response) => {
        this._wallets = response.data || this._wallets || [];
        if (!this._currentWallet) {
          const lsWallet = localStorage.getItem('currentWallet');
          const evmWallet = this._wallets.find(
            (w) => w.type === WalletType.EVM,
          );
          if (lsWallet) {
            this.setCurrentWallet(
              response.data?.find((w) => w.id === lsWallet) || evmWallet!,
            );
          } else {
            this.setCurrentWallet(evmWallet!);
          }
        } else {
          this.setCurrentWallet(
            response.data?.find((w) => w.id === this._currentWallet?.id) ||
            this._currentWallet,
          );
        }
        this._checkDone = response.status;
        this._isLoggedIn = true;
        if (response.status && response.data) {
          this.getReferralData();
        }
        return response;
      })
      .catch((response) => {
        this._checkDone = response.response.status;
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout(this.isBot);
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action.bound
  async getSnipePositions() {
    return api<PositionModel[]>({
      method: 'get',
      path: `/snipe-engine/positions`,
    })
      .then((response) => {
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async searchTokens(query: string) {
    const data: any = {};
    data['filter[search_query]'] = query;
    return api<IAsset[]>({
      method: 'get',
      path: `/token/search`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async getReferralData() {
    return api<ReferralProfileResponse>({
      method: 'get',
      path: `/referral/profile`,
    })
      .then((response) => {
        this._refData = response;
        this._refId = response.refCode;

        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async claimReferral(refWalletId: string, walletId: string) {
    return api<{ txHash: string; claimTxId: string }>({
      method: 'post',
      path: `/referral/reward/claim`,
      data: {
        refWalletId,
        walletId,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async loadConnectWalletInfo() {
    api<ApiConnectWallet>({
      method: 'get',
      path: `/wallet/connect`,
    })
      .then((response) => {
        if (response && response?.addresses?.length) {
          this._connectWallet = response;
        }
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async loadSnipeSettings() {
    api<ApiSnipeSettings>({
      method: 'get',
      path: `/snipe-engine/position/settings`,
    })
      .then((response) => {
        if (response && response?.label) {
          this._snipeSettings = response;
        }
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async saveSnipeSettings(data: ISnipeSettings) {
    return api<ApiSnipeSettings>({
      method: 'put',
      path: `/snipe-engine/position/settings`,
      data,
    })
      .then((response) => {
        if (response && response?.label) {
          this._snipeSettings = response;
        }
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async loadMnemonic(pinCode?: string) {
    const data = pinCode ? { pinCode } : undefined;
    return api<Mnemonic>({
      method: 'get',
      path: `/wallet/seed`,
      data,
    })
      .then((response) => {
        if (response && response?.mnemonic) {
          this._mnemonic = response.mnemonic;
        }
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async loadPrivateKey() {
    return api<ExportWalletResponse>({
      method: 'get',
      path: `/wallet/export`,
      data: {
        walletId: this.currentWallet?.id,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  setPinEnabled(value: string | null) {
    const v = !['null', 'false'].includes(String(value));
    localStorage.setItem('pinEnabled', v.toString());
    this._authStore.setPinEnabled(v);
    this._pinEnabled = v;
  }

  @action.bound
  async updatePin(data: UpdatePinParams) {
    return api<null>({
      method: 'patch',
      path: `/user/pin`,
      data,
    })
      .then((response) => {
        this.setPinEnabled(data.pinCode);
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async estimateBridge(data: EstimateBridgeRouteRequest) {
    return api<EstimateBridgeRouteResponse[]>({
      method: 'get',
      path: `/bridge/route/estimate`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async bridgeRoutes(blockchain: ChainId) {
    return api<BridgeRouteResponse[]>({
      method: 'get',
      path: `/bridge/route`,
      data: {
        blockchain,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((e) => this.errorHandler(e));
  }

  @action.bound
  async confirmBridge(data: BridgeTokensRequest) {
    return apiRaw<BridgeTokensResponse>({
      method: 'post',
      path: `/bridge`,
      data,
    })
      .then((response) => {
        if (response.status === 201 && response.data) {
          return response.data as unknown as BridgeTokensResponse;
        } else if (response.status === 401) {
          this.logout();
        }
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
      });
  }

  @action.bound
  errorHandler(error: AxiosError | any) {
    console.log('accountStore errorHandler', error);
    if (error instanceof AxiosError) {
      if (error.response?.status === 401) {
        this.logout();
      }
      if (error.response?.data) {
        console.error(error.response.data);
      } else {
        console.error('An unknown error occurred:', error);
      }
    } else {
      console.error('A non-Axios error occurred:', error);
    }
    return error;
  }

  @action.bound
  resetStore(soft = false) {
    if (!soft) {
      localStorage.removeItem('refreshToken');
    }
    localStorage.removeItem('token');
    clearAuthTokens();
    this._wallets = [];
    this._address = null;
    this._isLoggedIn = false;
    this._pinEnabled = null;
    this._token = null;
  }
}
