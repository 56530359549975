import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import { useTranslation } from 'react-i18next';
import { OrderTrigger } from 'types/order/order.enum';
import cn from 'classnames';
import LevelGroup, { LevelType } from './AlphaOrder/LevelGroup';
import SubtotalLevelsGroup from './AlphaOrder/SubtotalLevelsGroup';
import { AlphaOrderModel } from '../models/AlphaOrderModel';

const priceSources = [
  { value: OrderTrigger.PRICE_IN_USD, label: 'USD' },
  { value: OrderTrigger.MCAP, label: 'Mcap' },
];

const AlphaOrder = () => {
  const { t } = useTranslation();
  const { tokenStore, tokenTrade } = useStores();
  const { tokenDetails: token } = tokenStore;
  const { isBuy, order } = tokenTrade;

  const [state] = useState(() => new AlphaOrderModel(tokenStore, tokenTrade));

  const { isLevelsShow, priceSource } = state;

  const levelGroupOrder = useMemo(
    () =>
      isBuy
        ? [LevelType.UPPER, LevelType.LOWER]
        : [LevelType.LOWER, LevelType.UPPER],
    [isBuy],
  );

  return (
    token && (
      <div>
        <div className="d-flex justify-content-between align-items-center mt-4 mb-2">
          <div className="tx-17 tx-semibold">
            {t('order.alpha.level-range')}
          </div>
        </div>

        {/* <pre className="text-wrap">{JSON.stringify(state.errors, undefined, 2)}</pre> */}

        <div className="d-flex flex-column rounded-10px overflow-hidden gap-2px">
          <div className={cn(['card', 'flex-row', 'rounded-4-px', 'gap-2'])}>
            {priceSources.map(({ value, label }) => (
              <div
                key={value}
                className={cn([
                  order && 'disabled',
                  priceSource === value
                    ? 'btn-primary-10 bd-primary'
                    : 'btn-semi-10 bd-transparent',
                  'bd-solid',
                  'bd-1',
                  'btn',
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                  'wd-100p',
                  'rounded-8px',
                  'tx-13',
                  'tx-semibold',
                  'p-1',
                ])}
                onClick={() => state.setPriceSource(value)}
              >
                {label}
              </div>
            ))}
          </div>

          {levelGroupOrder.map((type) => (
            <LevelGroup key={type} state={state} type={type} />
          ))}

          {isLevelsShow && (
            <div className="card rounded-4-px gap-12px">
              <SubtotalLevelsGroup state={state} />
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default observer(AlphaOrder);
