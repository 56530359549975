import React, {useMemo} from "react";
import {ReferralLevelType} from "@pages/Referral/constants";

interface ReferralBadgeProps {
  type: ReferralLevelType;
  size?: number;
}

const ReferralBadge = ({type, size = 65}: ReferralBadgeProps) => {
  const fill = useMemo(() => {
    switch (type) {
      case ReferralLevelType.BRONZE:
        return '#D8B57E';
      case ReferralLevelType.SILVER:
        return '#C5CBD5';
      case ReferralLevelType.GOLD:
        return '#F2B94A';
      case ReferralLevelType.PLATINUM:
        return '#FFFFFF';
      case ReferralLevelType.ULTRA:
        return '#0000FF';
      default:
        return null;
    }
  }, [type]);

  return (
    <div className="pos-relative">
      <svg width={size} height={size} viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
        {!!fill && (
          <g>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M25.2416 3.52222C28.8049 1.49259 33.1951 1.49259 36.7584 3.52222L52.2416 12.3412C55.8049 14.3708 58 18.1218 58 22.181V39.819C58 43.8782 55.8049 47.6292 52.2416 49.6588L36.7584 58.4778C33.1951 60.5074 28.8049 60.5074 25.2416 58.4778L9.75844 49.6588C6.19511 47.6292 4 43.8782 4 39.819V22.181C4 18.1218 6.1951 14.3708 9.75844 12.3412L25.2416 3.52222Z"
                  fill={fill}/>
          </g>
        )}
        {type === ReferralLevelType.DIAMOND && (<>
          <g>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M25.2416 3.52222C28.8049 1.49259 33.1951 1.49259 36.7584 3.52222L52.2416 12.3412C55.8049 14.3708 58 18.1218 58 22.181V39.819C58 43.8782 55.8049 47.6292 52.2416 49.6588L36.7584 58.4778C33.1951 60.5074 28.8049 60.5074 25.2416 58.4778L9.75844 49.6588C6.19511 47.6292 4 43.8782 4 39.819V22.181C4 18.1218 6.1951 14.3708 9.75844 12.3412L25.2416 3.52222Z"
                  fill="url(#paint0_linear_5106_257)"/>
            <path
              d="M25.489 3.95669C28.899 2.01444 33.101 2.01444 36.511 3.95669L51.9941 12.7757C55.4033 14.7175 57.5 18.3037 57.5 22.181V39.819C57.5 43.6963 55.4033 47.2825 51.9941 49.2243L36.511 58.0433C33.101 59.9856 28.899 59.9856 25.489 58.0433L10.0059 49.2243C6.59671 47.2825 4.5 43.6963 4.5 39.819V22.181C4.5 18.3037 6.59671 14.7175 10.0059 12.7757L25.489 3.95669Z"
              stroke="url(#paint1_linear_5106_257)"/>
          </g>
          <defs>
            <linearGradient id="paint0_linear_5106_257" x1="4" y1="31" x2="58" y2="31" gradientUnits="userSpaceOnUse">
              <stop stopColor="#4FACFE"/>
              <stop offset="1" stopColor="#00F2FE"/>
            </linearGradient>
            <linearGradient id="paint1_linear_5106_257" x1="4" y1="2" x2="58" y2="60" gradientUnits="userSpaceOnUse">
              <stop stopColor="white" stopOpacity="0.5"/>
              <stop offset="0.333333" stopColor="white" stopOpacity="0.25"/>
              <stop offset="0.666667" stopColor="white" stopOpacity="0.25"/>
              <stop offset="1" stopColor="white" stopOpacity="0.5"/>
            </linearGradient>
          </defs>
        </>)}
        {type === ReferralLevelType.MASTER && (<>
          <g>
            <path fillRule="evenodd"
                  d="M25.2416 3.52222C28.8049 1.49259 33.1951 1.49259 36.7584 3.52222L52.2416 12.3412C55.8049 14.3708 58 18.1218 58 22.181V39.819C58 43.8782 55.8049 47.6292 52.2416 49.6588L36.7584 58.4778C33.1951 60.5074 28.8049 60.5074 25.2416 58.4778L9.75844 49.6588C6.19511 47.6292 4 43.8782 4 39.819V22.181C4 18.1218 6.1951 14.3708 9.75844 12.3412L25.2416 3.52222Z"
                  fill="url(#paint0_linear_5106_914)"/>
            <path
              d="M25.5366 4.04024C28.917 2.11479 33.083 2.11479 36.4634 4.04024L51.9465 12.8592C55.3261 14.7842 57.4038 18.3387 57.4038 22.181V39.819C57.4038 43.6613 55.3261 47.2158 51.9465 49.1408L36.4634 57.9598C33.083 59.8852 28.917 59.8852 25.5366 57.9598L10.0535 49.1408C6.67394 47.2158 4.59615 43.6613 4.59615 39.819V22.181C4.59615 18.3387 6.67394 14.7842 10.0535 12.8592L25.5366 4.04024Z"
              stroke="url(#paint1_linear_5106_914)" strokeWidth="1.19231"/>
          </g>
          <defs>
            <linearGradient id="paint0_linear_5106_914" x1="4" y1="2" x2="58" y2="60" gradientUnits="userSpaceOnUse">
              <stop stopColor="#B37DDD"/>
              <stop offset="1" stopColor="#767BDD"/>
            </linearGradient>
            <linearGradient id="paint1_linear_5106_914" x1="4" y1="2" x2="58" y2="60" gradientUnits="userSpaceOnUse">
              <stop stopColor="white" stopOpacity="0.5"/>
              <stop offset="0.333333" stopColor="white" stopOpacity="0.25"/>
              <stop offset="0.666667" stopColor="white" stopOpacity="0.25"/>
              <stop offset="1" stopColor="white" stopOpacity="0.5"/>
            </linearGradient>
          </defs>
        </>)}
      </svg>
      <div className="position-absolute absolute-item d-flex align-items-center justify-content-center">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          {type !== ReferralLevelType.PLATINUM && (<>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M13.6584 5.25004C12.6246 4.70163 11.3753 4.70163 10.3416 5.25004L4.80572 8.18686C3.69261 8.77738 3 9.91234 3 11.1459V16.6929C3 17.9264 3.69261 19.0614 4.80572 19.6519L10.3416 22.5887C11.3753 23.1371 12.6246 23.1371 13.6584 22.5887L19.1943 19.6519C20.3074 19.0614 21 17.9264 21 16.6929V11.1459C21 9.91234 20.3074 8.77738 19.1943 8.18686L13.6584 5.25004ZM12.0002 10.5491C11.6722 10.5491 11.4528 10.932 11.014 11.6978L10.9005 11.896C10.7759 12.1136 10.7136 12.2223 10.6163 12.2941C10.5191 12.3659 10.3981 12.3927 10.156 12.4459L9.93552 12.4944C9.08342 12.682 8.6574 12.7757 8.55604 13.0929C8.45468 13.41 8.74511 13.7404 9.32599 14.4013L9.47628 14.5723C9.64134 14.76 9.72387 14.854 9.76105 14.9701C9.79811 15.0863 9.78564 15.2115 9.7607 15.4622L9.73796 15.6902C9.65015 16.572 9.60625 17.0128 9.87155 17.2089C10.137 17.4048 10.5359 17.2262 11.3336 16.8688L11.54 16.7764C11.7668 16.6748 11.88 16.624 12.0002 16.624C12.1205 16.624 12.2337 16.6748 12.4605 16.7764L12.6669 16.8688C13.4646 17.2262 13.8635 17.4048 14.1289 17.2089C14.3943 17.0128 14.3503 16.572 14.2625 15.6902L14.2398 15.4622C14.2149 15.2115 14.2024 15.0863 14.2394 14.9701C14.2766 14.854 14.3592 14.76 14.5242 14.5723L14.6745 14.4013C15.2554 13.7404 15.5459 13.41 15.4445 13.0929C15.3431 12.7757 14.917 12.682 14.065 12.4944L13.8445 12.4459C13.6024 12.3927 13.4814 12.3659 13.3842 12.2941C13.2869 12.2223 13.2246 12.1136 13.1 11.896L12.9865 11.6978C12.5477 10.932 12.3283 10.5491 12.0002 10.5491Z"
                  fill="white"/>
            <path
              d="M10.845 1H13.1544C15.3317 1 16.4204 1 17.0968 1.65809C17.7732 2.31617 17.7732 3.37535 17.7732 5.4937V5.51389L14.487 3.77054C12.9364 2.94795 11.0625 2.94794 9.51189 3.77054L6.22618 5.51364V5.4937C6.22618 3.37535 6.22618 2.31617 6.90259 1.65809C7.57899 1 8.66766 1 10.845 1Z"
              fill="white"/>
          </>)}
          {type === ReferralLevelType.PLATINUM && (<>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M13.6584 5.25004C12.6246 4.70163 11.3753 4.70163 10.3416 5.25004L4.80572 8.18686C3.69261 8.77738 3 9.91234 3 11.1459V16.6929C3 17.9264 3.69261 19.0614 4.80572 19.6519L10.3416 22.5887C11.3753 23.1371 12.6246 23.1371 13.6584 22.5887L19.1943 19.6519C20.3074 19.0614 21 17.9264 21 16.6929V11.1459C21 9.91234 20.3074 8.77738 19.1943 8.18686L13.6584 5.25004ZM12.0002 10.5491C11.6722 10.5491 11.4528 10.932 11.014 11.6978L10.9005 11.896C10.7759 12.1136 10.7136 12.2223 10.6163 12.2941C10.5191 12.3659 10.3981 12.3927 10.156 12.4459L9.93552 12.4944C9.08342 12.682 8.65739 12.7757 8.55604 13.0929C8.45468 13.41 8.74511 13.7404 9.32599 14.4013L9.47628 14.5723C9.64134 14.76 9.72387 14.854 9.76105 14.9701C9.79811 15.0863 9.78564 15.2115 9.7607 15.4622L9.73795 15.6902C9.65015 16.572 9.60625 17.0128 9.87155 17.2089C10.137 17.4048 10.5359 17.2262 11.3336 16.8688L11.54 16.7764C11.7668 16.6748 11.88 16.624 12.0002 16.624C12.1205 16.624 12.2337 16.6748 12.4605 16.7764L12.6669 16.8688C13.4646 17.2262 13.8635 17.4048 14.1289 17.2089C14.3943 17.0128 14.3503 16.572 14.2625 15.6902L14.2398 15.4622C14.2149 15.2115 14.2024 15.0863 14.2394 14.9701C14.2766 14.854 14.3592 14.76 14.5242 14.5723L14.6745 14.4013C15.2554 13.7404 15.5459 13.41 15.4445 13.0929C15.3431 12.7757 14.917 12.682 14.065 12.4944L13.8445 12.4459C13.6024 12.3927 13.4814 12.3659 13.3842 12.2941C13.2869 12.2223 13.2246 12.1136 13.1 11.896L12.9865 11.6978C12.5477 10.932 12.3283 10.5491 12.0002 10.5491Z"
                  fill="url(#paint0_linear_5106_986)"/>
            <path
              d="M10.845 1H13.1544C15.3317 1 16.4204 1 17.0968 1.65809C17.7732 2.31617 17.7732 3.37535 17.7732 5.4937V5.51389L14.487 3.77054C12.9364 2.94795 11.0625 2.94794 9.51189 3.77054L6.22618 5.51364V5.4937C6.22618 3.37535 6.22618 2.31617 6.90259 1.65809C7.57899 1 8.66766 1 10.845 1Z"
              fill="url(#paint1_linear_5106_986)"/>
            <defs>
              <linearGradient id="paint0_linear_5106_986" x1="3" y1="12" x2="21" y2="12" gradientUnits="userSpaceOnUse">
                <stop stopColor="#E6E9F0"/>
                <stop offset="1" stopColor="#EEF1F5"/>
              </linearGradient>
              <linearGradient id="paint1_linear_5106_986" x1="3" y1="12" x2="21" y2="12" gradientUnits="userSpaceOnUse">
                <stop stopColor="#E6E9F0"/>
                <stop offset="1" stopColor="#EEF1F5"/>
              </linearGradient>
            </defs>
          </>)}
        </svg>
      </div>
    </div>
  );
};

export default ReferralBadge;
