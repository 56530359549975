export const updateLocalStorageObject = (
  key: string,
  update: Record<string, any>,
) => {
  const ls = JSON.parse(localStorage.getItem(key) ?? '{}');
  localStorage.setItem(
    key,
    JSON.stringify({
      ...ls,
      ...update,
    }),
  );
};

export const getLocalStorageObject = (
  key: string,
): Record<string, any> | null => {
  const i = localStorage.getItem(key);

  return i && JSON.parse(i);
};

export const removeLocalStorageObject = (key: string) => {
  return localStorage.removeItem(key);
};
