import React, { useEffect, useMemo, useState } from 'react';
import { walletToChain, walletTypeLogo, walletTypeName } from '@helpers/chains';
import arrow from '@assets/icons/chevron-down.svg';
import settings from '@assets/icons/cog.svg';
import chevron from '@assets/icons/chevron-right-gray.svg';
import plus from '@assets/icons/plus.svg';
import { Modal } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import { getEllipsisTxt } from '@helpers/formatters';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChainId, PageRoutes } from '../constants';
import { WalletType } from '../types/enums';
import UserAvatar from '@components/common/UserAvatar';
import Vibrutton from '@components/common/Vibrutton';
import BadgeIcon from '@components/common/BadgeIcon';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';

interface WalletSelectionProps {
  children?: React.ReactNode;
  className?: string;
  onChange?: (id: string) => void;
  enabledTypes?: WalletType[];
  filterTypes?: WalletType[];
  disabledTypes?: WalletType[];
  hideCurrent?: boolean;
  setOnChange?: boolean;
  selectedWallet?: string;
  enforceExternalFilter?: boolean;
}

const WalletSelection = observer((props: WalletSelectionProps) => {
  const {
    children,
    className = 'd-flex btn btn-transparent bd-0 p-0 tx-left',
    onChange,
    filterTypes,
    enabledTypes = [],
    disabledTypes = [],
    hideCurrent = false,
    setOnChange = false,
    selectedWallet,
    enforceExternalFilter,
  } = props;
  const { t } = useTranslation();
  const { accountStore } = useStores();
  const { wallets, currentWallet, existingWalletTypes, selectedWalletGroup } =
    accountStore;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [w] = useAmplitude();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  type ListItem = { type: WalletType | 'all'; active: boolean };

  const types = useMemo<ListItem[]>(() => {
    const order = [
      WalletType.EVM,
      WalletType.SUI,
      WalletType.SOL,
      WalletType.TRON,
      WalletType.TON,
    ];
    let walletTypes = Object.values(WalletType)
      .slice(0, 5)
      .sort((a, b) => {
        return order.indexOf(a) - order.indexOf(b);
      });
    if (enabledTypes.length) {
      walletTypes = walletTypes.filter((type) => enabledTypes.includes(type));
    } else if (disabledTypes.length) {
      walletTypes = walletTypes.filter((type) => !disabledTypes.includes(type));
    }

    const list: ListItem[] = walletTypes.map((type) => ({
      type,
      active: Array.from(existingWalletTypes).includes(type),
    }));

    return [{ type: 'all', active: true }, ...list];
  }, [enabledTypes, disabledTypes, existingWalletTypes]);

  const allowedTypeList = useMemo(() => {
    return types.filter(({ active }) => active).map(({ type }) => type);
  }, [types]);

  const allowedWallets = useMemo(() => {
    return wallets.filter((type) => allowedTypeList.includes(type.type));
  }, [wallets, allowedTypeList]);

  const handleChange = (id: string) => {
    setShowModal(false);
    if (onChange) {
      onChange(id);
      if (!setOnChange) {
        return;
      }
    }

    const wallet = wallets.find((wallet) => wallet.id === id)!;

    if (wallet.type !== currentWallet?.type) {
      accountStore.setNetwork(walletToChain(wallet.type), true);
    }
    accountStore.setCurrentWallet(wallet);

    setShowModal(false);
  };

  const handleSettings = () => navigate(PageRoutes.WALLET_SETTINGS, { state: { back: pathname } });

  const endIconParams = onChange
    ? {
        src: chevron,
        width: 16,
      }
    : {
        src: settings,
        width: 20,
        onClick: w(
          handleSettings,
          AmplitudeEvent.WALLET_SETTINGS_ICON_CLICKED,
          { wallet: currentWallet },
        ),
      };

  const filteredWallets = useMemo(() => {
    const filter = enforceExternalFilter
      ? enabledTypes || [selectedWalletGroup]
      : [selectedWalletGroup];

    return wallets.filter(
      (w) =>
        (filterTypes?.length
          ? filterTypes?.includes(w.type)
          : (selectedWalletGroup === 'all' || filter.includes(w.type)) &&
            types.map(({ type }) => type).includes(w.type)) &&
        (hideCurrent ? w.id !== currentWallet?.id : true),
    );
  }, [selectedWalletGroup, wallets, currentWallet, filterTypes, hideCurrent]);

  useEffect(() => {
    if (showModal && currentWallet) {
      accountStore.setSelectedWalletGroup(currentWallet.type);
    }
  }, [showModal, currentWallet]);

  return (
    <>
      <Vibrutton
        className={className}
        onClick={w(
          () => setShowModal(true),
          AmplitudeEvent.WALLET_MANAGEMENT_SCREEN_VIEWED,
        )}
      >
        {children ? (
          children
        ) : (
          <div className="tx-17 lh-3">
            {currentWallet?.name ||
              getEllipsisTxt(currentWallet?.address || '', 4, '0x')}
            <img src={arrow} alt="Choose wallet" width={16} />
          </div>
        )}
      </Vibrutton>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header className="modal-header-new with-border" closeButton>
          <div>
            <Modal.Title>{t('wallet.wallets')}</Modal.Title>

            {!filterTypes?.length && types.length > 2 && (
              <div className="d-flex">
                <div className="d-flex flex-wrap flex-row pb-2 pt-2 gap-1">
                  {types.map(({ type, active }) => (
                    <div
                      className={cn([
                        'btn',
                        'mn-wd-90',
                        'wd-max-content',
                        'border-1',
                        'border-semi-transparent-10',
                        'border-solid',
                        'rounded-pill',
                        'tx-14',
                        'tx-normal',
                        'p-2',
                        type === selectedWalletGroup
                          ? 'btn-semi-10 bg-semi-transparent-10'
                          : 'btn-transparent',
                      ])}
                      style={{ padding: '10px 12px' }}
                      key={'wallet-type-' + type}
                      onClick={() =>
                        active &&
                        w(
                          () => accountStore.setSelectedWalletGroup(type),
                          AmplitudeEvent.WALLETS_FILTER_CLICKED,
                          { type },
                        )(type)
                      }
                    >
                      <div className="d-flex align-items-center tx-15 gap-2">
                        {type !== 'all' && (
                          <img
                            className={cn([
                              !active && 'opacity-50',
                              'wd-auto',
                              'ht-25',
                            ])}
                            src={walletTypeLogo(type, true)}
                            alt="chain"
                          />
                        )}

                        <div
                          className={cn([
                            type !== 'all' && 'tx-uppercase',
                            !active && 'tx-muted',
                            'wd-100p',
                            'text-center',
                          ])}
                        >
                          {t(`common.${walletTypeName(type)}`, {
                            defaultValue: walletTypeName(type),
                          })}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Modal.Header>
        <Modal.Body className="pt-2 d-flex flex-column">
          <div className="ht-100p overflow-scroll">
            {allowedWallets.map(({ id, name, address, type }) => (
              <Vibrutton
                className={`btn ${id === (selectedWallet ?? currentWallet?.id) ? 'btn-semi-10 bg-semi-transparent-10' : 'btn-transparent'} ${filteredWallets.find((w) => w.id === id) ? 'd-flex' : 'd-none'} align-items-center px-3 py-2 wd-100p mb-2 tx-14 tx-semibold`}
                onClick={() => handleChange(id)}
                key={`wallet-selection-item-${address}-${id}`}
              >
                <div className="pos-relative">
                  <UserAvatar string={address} />
                  <div className="chain-label no-border height-oriented z-index-100">
                    <img src={walletTypeLogo(type, true)} alt="Chain" />
                  </div>
                </div>
                <div className="d-flex flex-column align-items-start justify-content-center ms-2">
                  <div className="d-flex">
                    <span className="me-1">
                      {name || getEllipsisTxt(address, 4, '0x')}
                    </span>
                  </div>
                  {!!name && (
                    <div className="tx-12 tx-muted tx-normal">
                      {getEllipsisTxt(address, 4, '0x')}
                    </div>
                  )}
                </div>
                <img alt="Choose" className="ms-auto" {...endIconParams} />
              </Vibrutton>
            ))}

            {!filteredWallets.length && (
              <div className="tx-center my-4">
                <BadgeIcon className="tx-40" badgeSize={85}>
                  😢
                </BadgeIcon>
                <div className="tx-28 tx-semibold my-2">
                  {t('wallet.no-wallet', { type: currentWallet?.type })}
                </div>
                <div className="tx-17 tx-muted">
                  {t('wallet.no-wallet-message', { type: currentWallet?.type })}
                </div>
              </div>
            )}
          </div>
          {!filterTypes?.length && (
            <button
              className="btn btn-semi-10 wd-100p tx-17 mt-4"
              onClick={w(
                () => {
                  setShowModal(false);
                  navigate(PageRoutes.NEW_WALLET_CREATE, { state: { back: pathname } });
                },
                AmplitudeEvent.ADD_WALLET_BUTTON_CLICKED,
                { screen: 'Wallet Management' },
              )}
            >
              <img src={plus} alt="plus" className="me-1" width={16} />
              {t('wallet.add-wallet')}
            </button>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
});

export default WalletSelection;
