import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {PageRoutes} from "../../constants";
import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import key from "@assets/icons/key-teal.svg";
import Preloader from "@components/common/Preloader";
import useResponseHandler from "@hooks/useResponseHandler";
import { useTranslation } from "react-i18next";
import BadgeIconNew from '@components/common/BadgeIconNew';
import { removeNewlinesAndSpaces } from '@helpers/strings';

const PrivateKeyImport = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accountStore, walletStore } = useStores();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const handleResponse = useResponseHandler();
  const location = useLocation();

  const cancelLoading = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (!location.state?.type) {
      navigate(-1);
    }
  }, [location.state]);

  const updateValue = (val: string) => {
    const formattedVal = val.replace(/<[^>]*>/g, '');
    setValue(formattedVal);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    walletStore.importWallet(location.state.type, removeNewlinesAndSpaces(value), 'Imported Wallet').then((response) => {
      if (response && response.address) {
        accountStore.loadUser().then((load) => {
          if (load && load.data) {
            accountStore.setCurrentWallet(response);
            navigate(PageRoutes.NEW_WALLET_NAME);
          } else {
            handleResponse(response.response, true);
            cancelLoading();
          }
        }).catch((error) => {
          handleResponse(error.message, true);
          cancelLoading();
        });
      } else {
        handleResponse(response.response, true);
        cancelLoading();
      }
    }).catch((error) => {
      handleResponse(error.message, true);
      cancelLoading();
    });
  }

  if (isLoading) {
    return (
      <div className="tx-center full-page wd-100p d-flex flex-column justify-content-center align-items-center">
        <Preloader inline iconSize={32} className="d-flex flex-column align-items-center" textClass="d-none" iconClass="d-block" text="" />
      </div>
    )
  }

  return (
    <div className="tx-center full-page wd-100p d-flex flex-column justify-content-center align-items-center pt-5">
      <BadgeIconNew className="mb-3">
        <img src={key} alt="key" width={20} />
      </BadgeIconNew>

      <h1 className="tx-24 mb-0">{t('wallet.paste-private-key')}</h1>
      <div className="tx-muted tx-14 mt-1 mb-3">
        {t('wallet.insert-private-key-p1')} <span className="tx-white text-decoration-underline">{t('wallet.insert-private-key-p2')}</span> {t('wallet.insert-private-key-p3')}
      </div>

      <div className="ht-130 d-flex align-items-center justify-content-center tx-center mt-5 mb-3 wd-100p">
        <textarea
          className="form-control ht-130 wd-100p tx-22 px-0 py-0 tx-center border-0 bg-transparent appearance-none no-resize"
          placeholder={t('wallet.paste-private-key')}
          value={value}
          onChange={(e) => updateValue(e.target.value)}
        />
      </div>

      <button
        className="btn btn-primary-10 wd-100p mt-auto mb-0"
        disabled={!value}
        onClick={handleSubmit}
      >
        {t('common.continue')}
      </button>
    </div>
  )
});

export default PrivateKeyImport;
