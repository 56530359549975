import React, { useState } from 'react';
import Badge, { BadgeVariant } from '@components/common/Badge';
import Vibrutton from '@components/common/Vibrutton';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

export interface TabItem {
  label: string;
  component: React.ReactNode;
  disabled?: boolean;
  badge?: string;
  badgeVariant?: BadgeVariant;
  labelClassName?: string;
}

export interface TabToolItem {
  icon?: string;
  label: string;
  handler?: () => void;
}

interface TabsProps {
  items: TabItem[];
  tools?: TabToolItem[];
  onChange?: (index: number) => void;
  activeIndex?: number;
  className?: string;
  navClassName?: string;
  containerClassName?: string;
  buttonClassName?: string;
}

const Tabs = (props: TabsProps) => {
  const { t } = useTranslation();
  const { items, tools, onChange, activeIndex, className, navClassName, containerClassName, buttonClassName = '' } = props;
  const [active, setActive] = useState(activeIndex || 0);

  const clickHandler = (item: TabItem, index: number) => {
    if (!item.disabled) {
      setActive(index);
      onChange?.(index);
    }
  }

  return (
    <div className={cn(['tabs', className])}>
      <div className={cn(['tabs-nav', navClassName])}>
        {items.map((item, index) => (
          <Vibrutton
            key={index}
            className={cn(
              'tabs-nav-item',
              buttonClassName,
              {
                disabled: item.disabled,
                active: active === index,
              },
            )}
            onClick={() => clickHandler(item, index)}
          >
            {t(item.label)}
            {item.badge && (<Badge label={t(item.badge)} variant={item.badgeVariant} className="ms-2" />)}
          </Vibrutton>
        ))}
        {tools?.map((item, index) => (
          <Vibrutton
            key={index}
            className={cn(
              'tabs-nav-item',
              'float-end',
              buttonClassName,
            )}
            onClick={item.handler}
          >
            {t(item.label)}
            {item.icon && (<img src={item.icon} title={t(item.label)} alt={t(item.label)} />)}
          </Vibrutton>
        ))}
      </div>
      <div className={cn(['tabs-container', containerClassName])}>
        {items[active].component}
      </div>
    </div>
  )
};

export default Tabs;
