import React from 'react';
import cn from 'classnames';
import { ColorVariant } from '../../types';

export type BadgeVariant = ColorVariant | 'custom';

interface BadgeProps {
  variant?: BadgeVariant;
  label?: string | number;
  className?: string;
  style?: Record<string, string>;
  children?: React.ReactNode;
}

export default function Badge({
  variant = 'primary',
  label,
  className = 'tx-12 tx-normal rounded-pill',
  style,
  children,
}: BadgeProps) {
  return (
    <div
      style={style}
      className={cn([
        'badge',
        variant !== 'custom' && `bg-semi-${variant}-10 tx-${variant}`,
        className,
      ])}
    >
      {children}
      {label}
    </div>
  );
}
