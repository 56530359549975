import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FormattedNumber from '@components/common/FormattedNumber';
import ButtonGroup from '@components/common/ButtonGroup';
import warning from '@assets/icons/warning-square.svg';
import Vibrutton from '@components/common/Vibrutton';
import { isIOS } from '@helpers/device';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import { ActiveBuySellSetting } from 'types/flip/flip-settings.request';
import { OrderType } from 'types';
import { SolPriorityMode } from 'types/user/settings.types';
import BigNumber from 'bignumber.js';
import { Blockchain } from 'types/enums';
import useNotification from '@hooks/useNotification';

interface SolanaFlipButtonsProps {
  isPlaceholder?: boolean;
}

const SolanaFlipButtons = observer(
  ({ isPlaceholder = false }: SolanaFlipButtonsProps) => {
    const { t } = useTranslation();
    const notify = useNotification();
    const { flipStore, accountStore } = useStores();
    const { currentWallet, network } = accountStore;
    const {
      flipSettings,
      flipOrderParams,
      tokenDetails,
      tokenBalance,
      balance,
    } = flipStore;
    const {
      buyAntiMev,
      sellAntiMev,
      priorityMode,
      activeBuySellSetting,
      panicButton,
      buyAmount,
      sellAmount,
      useWSol,
    } = flipOrderParams;

    const appliedFlipSettings = useMemo(
      () =>
        flipStore.getFlipQuickParams({
          buyAntiMev,
          sellAntiMev,
          priorityMode,
        }),
      [flipSettings, flipOrderParams],
    );

    const setActiveBuySellSetting = useCallback(
      (t: 'buy' | 'sell') => (v: string | number) => {
        const [ot] = ['buy', 'sell'].filter((v) => v !== t) as unknown as (
          | 'buy'
          | 'sell'
        )[];

        flipStore.setFlipOrderParams({
          [`${t}Amount`]: v,
          activeBuySellSetting: {
            [ot]: (activeBuySellSetting ?? {})[ot],
            [t]: v,
          } as ActiveBuySellSetting,
        });
      },
      [activeBuySellSetting],
    );

    const activeButtonIdx = useCallback(
      (key: 'buy' | 'sell') => {
        const isBuy = key === 'buy';
        const compare = isBuy ? buyAmount : sellAmount;
        return flipOrderParams.buySellSettings[key].findIndex(
          (i) => i === compare,
        );
      },
      [flipOrderParams],
    );

    const handleCreateOrder =
      ({ panic = false, type }: { panic?: boolean; type: OrderType }) =>
      async () => {
        if (isPlaceholder) return;

        const isBuy = type === OrderType.MARKET_BUY;

        if (isBuy) {
          if (
            useWSol
              ? new BigNumber(balance?.balanceWrapped || 0).isLessThan(
                  buyAmount || 0,
                )
              : new BigNumber(balance?.balance || 0).isLessThanOrEqualTo(
                  buyAmount || 0,
                )
          ) {
            notify(t('order.insufficient-balance'), { type: 'danger' });
            return;
          }
        } else if (
          new BigNumber(tokenBalance?.tokenBalance || 0).isLessThan(
            new BigNumber(sellAmount || 0)
              .div(100)
              .times(tokenBalance?.tokenBalanceInNative || 0) || 0,
          ) ||
          new BigNumber(balance?.balance || 0).isLessThanOrEqualTo(0.0001)
        ) {
          notify(t('order.insufficient-balance'), { type: 'danger' });
          return;
        } else if (
          new BigNumber(tokenBalance?.tokenBalance || 0).isLessThanOrEqualTo(
            0,
          ) ||
          new BigNumber(balance?.balance || 0).isLessThanOrEqualTo(0.0001)
        ) {
          notify(t('order.insufficient-balance'), { type: 'danger' });
          return;
        }

        const tBalance = new BigNumber(
          tokenBalance?.tokenBalance || 0,
        ).valueOf();
        const allOut = panic || new BigNumber(sellAmount || 0).eq(100);

        const payload = {
          flipMode: true,
          walletId: currentWallet!.id,
          type,
          valueOut: isBuy
            ? new BigNumber(buyAmount ?? 0).valueOf()
            : allOut
              ? tBalance
              : new BigNumber(tBalance)
                  .times(sellAmount ?? 0)
                  .div(100)
                  .valueOf(),
          valueIn: isBuy
            ? new BigNumber(buyAmount ?? 0).valueOf()
            : allOut
              ? tBalance
              : new BigNumber(tBalance)
                  .times(sellAmount ?? 0)
                  .div(100)
                  .valueOf(),
          metadata: {
            sellSolValue: isBuy
              ? undefined
              : allOut
                ? tBalance
                : new BigNumber(tBalance)
                    .times(sellAmount ?? 0)
                    .div(100)
                    .toString(),
          },
          blockchain: network as unknown as Blockchain,
          pairAddress: tokenDetails!.pairAddress,
          settings: {
            antiMevEnabled: isBuy
              ? flipOrderParams.buyAntiMev
              : flipOrderParams.sellAntiMev,
            slippage: new BigNumber(
              isBuy
                ? flipOrderParams.buySlippage
                : panic
                  ? 100
                  : flipOrderParams.sellSlippage,
            ).toNumber(),
            solPriorityMode: panic
              ? SolPriorityMode.DEGEN
              : SolPriorityMode.CUSTOM,
            solPriorityFee: new BigNumber(
              isBuy ? flipOrderParams.buyFee : flipOrderParams.sellFee,
            ).toNumber(),
            useWSol: flipOrderParams?.useWSol ?? false,
            useBloxroute: flipOrderParams?.useBloxroute ?? false,
          },
        };

        console.time('tradeBuy');

        await flipStore
          .createOrder(payload)
          .catch(({ success, data, frontendId }) => {
            if (!success) {
              notify(data, { type: 'danger' });
              flipStore.clearHistoryPendingItem(frontendId);
            }
          });
      };

    return (
      <div
        className={`px-3 py-2 bg-dark ${isPlaceholder ? 'op-0 pointer-events-none' : 'fixed-bottom'} ${isIOS() ? 'pb-5' : ''}`}
      >
        <div className="d-flex justify-content-between align-items-center gap-2 mb-2">
          <Vibrutton
            className="btn btn-blue wd-100p px-0 py-2 tx-14"
            onClick={handleCreateOrder({ type: OrderType.MARKET_BUY })}
            protectFromDrag
          >
            <span>{t('common.buy')}</span>
            <span>
              :{' '}
              {flipOrderParams.activeBuySellSetting?.buy ||
                flipOrderParams.buyAmount}{' '}
              {useWSol ? 'WSOL' : 'SOL'}
            </span>
          </Vibrutton>
          <div className={`${panicButton ? 'alpha-button-group' : ''} wd-100p`}>
            <Vibrutton
              className="btn btn-secondary wd-100p px-0 py-2 tx-14"
              onClick={handleCreateOrder({ type: OrderType.MARKET_SELL })}
              protectFromDrag
            >
              <span>{t('common.sell')}</span>
              <FormattedNumber
                value={
                  flipOrderParams.activeBuySellSetting?.sell ||
                  flipOrderParams.sellAmount
                }
                suffix=": "
                postfix="%"
              />
            </Vibrutton>
            {panicButton && (
              <Vibrutton
                className="btn btn-secondary px-2 py-2 d-flex align-items-center justify-content-center"
                onClick={handleCreateOrder({
                  panic: true,
                  type: OrderType.MARKET_SELL,
                })}
              >
                <img
                  src={warning}
                  alt="warning"
                  width={20}
                  className="mn-wd-20 mx-wd-20 invert"
                />
              </Vibrutton>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center gap-2">
          {['buy', 'sell'].map((k) => {
            const key = k as 'buy' | 'sell';
            const isBuy = k.includes('buy');

            return (
              <ButtonGroup
                key={key + Math.random()}
                items={(
                  flipOrderParams.buySellSettings[key] ||
                  appliedFlipSettings[`${key}Buttons`]
                ).map((value) => ({
                  value,
                  variant: 'primary',
                }))}
                onChange={setActiveBuySellSetting(key)}
                activeIndex={activeButtonIdx(key)}
                postfix={isBuy ? '' : '%'}
              />
            );
          })}
        </div>
      </div>
    );
  },
);

export default SolanaFlipButtons;
