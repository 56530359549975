import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { firstLast, getEllipsisTxt } from '@helpers/formatters';
import Vibrutton from '@components/common/Vibrutton';
import { useTranslation } from 'react-i18next';
import history from '@assets/icons/history.svg';
import chevron from '@assets/icons/chevron-right-gray.svg';
import cn from 'classnames';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import UserAvatar from '@components/common/UserAvatar';
import { walletTypeLogo } from '@helpers/chains';
import TokenName from '@components/common/TokenName';

interface SendHistoryProps {
  onSelect: (v: string) => void;
}

const SendHistory = observer(({ onSelect }: SendHistoryProps) => {
  const { t } = useTranslation();
  const { walletStore, accountStore } = useStores();
  const { wallets } = accountStore;
  const { recentWallets } = walletStore;
  const [show, setShow] = useState<boolean>(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleSelect = (w: string) => {
    onSelect(w);
    handleClose();
  };

  if (!recentWallets.length) return null;

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('common.recent-wallets')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {recentWallets.map((w) => {
            const address = w[0];
            const found = wallets.find((wallet) => wallet.address === address);

            return (
              <Vibrutton
                className={`btn btn-semi-10 bg-semi-transparent-10 d-flex align-items-center px-3 py-2 wd-100p mb-2 tx-14 tx-semibold`}
                onClick={() => handleSelect(address)}
                key={`recent-wallets-${address}`}
              >
                <div className="pos-relative">
                  {found ? <UserAvatar string={address} /> : <TokenName text={firstLast(address, '0x')} size={40} />}
                </div>
                <div className="d-flex flex-column align-items-start justify-content-center ms-2">
                  <div className="d-flex">
                      <span className="me-1">
                        {found ? found.name : getEllipsisTxt(address, 6, '0x')}
                      </span>
                  </div>
                  <div className="tx-12 tx-muted tx-normal">
                    {found?.name ? getEllipsisTxt(address, 4, '0x') : format(new Date(w[1]), 'dd.MM.yyyy HH:mm')}
                  </div>
                </div>
                <img src={chevron} alt={t('common.recent-wallets')} className="wd-25 ht-25 ms-auto me-0" />
              </Vibrutton>
        //       <Vibrutton
        //         className={cn([
        //           'btn btn-secondary wd-100p',
        //           'card flex-row align-items-center justify-content-between',
        //           'mb-2',
        //         ])}
        //         key={`recent-wallets-${w}`}
        //         onClick={() => handleSelect(w[0])}
        //       >
        //         <div>
        //           <div>{getEllipsisTxt(w[0], 6, '0x')}</div>
        //           <div className="tx-muted tx-12">
        //             {format(new Date(w[1]), 'dd.MM.yyyy, HH:mm')}
        //           </div>
        //         </div>
        //         <img src={chevron} alt={getEllipsisTxt(w[0], 4, '0x')} />
        //       </Vibrutton>
            )
          })}

          <Vibrutton
            className="btn btn-transparent wd-100p mt-4 mb-3"
            onClick={handleClose}
          >
            {t('common.cancel')}
          </Vibrutton>
        </Modal.Body>
      </Modal>

      <Vibrutton
        className="btn btn-link p-0 mx-0 my-0 lh-1 d-flex align-items-center justify-content-center wd-25 ht-25"
        onClick={() => setShow(true)}
      >
        <img src={history} alt={t('common.recent-wallets')} className="wd-25 ht-25" />
      </Vibrutton>
    </>
  );
});

export default SendHistory;
