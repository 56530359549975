import { createContext } from 'react';
import { SocketStore } from '@stores/socket-store';
import { AuthStore } from '@stores/auth-store';
import { AccountStore } from '@stores/account-store';
import { SettingsStore } from '@stores/settings-store';
import { SwapStore } from '@stores/swap-store';
import { OrdersStore } from '@stores/orders-store';
import { TokenStore } from '@stores/token-store';
import { TokenTrade } from '@stores/token-trade';
import { QuickTradeStore } from '@stores/quick-trade-store';
import { WhaleSignalsStore } from '@stores/whale-signals-store';
import { WalletStore } from '@stores/wallet-store';
import { BlockchainStore } from '@stores/blockchain-store';
import { ResearchStore } from '@stores/research-store';
import { FlipStore } from '@stores/flip-store';

const authStore = new AuthStore();
const accountStore = new AccountStore(authStore);
const walletStore = new WalletStore(accountStore);
const blockchainStore = new BlockchainStore(accountStore);
const researchStore = new ResearchStore(accountStore);
const socketStore = new SocketStore(authStore, accountStore);
const settingsStore = new SettingsStore(authStore, accountStore);
const ordersStore = new OrdersStore(socketStore, accountStore, walletStore);
const tokenStore = new TokenStore(socketStore, accountStore, ordersStore, blockchainStore);
const flipStore = new FlipStore(socketStore, accountStore, settingsStore, ordersStore, blockchainStore);
const tokenTrade = new TokenTrade(tokenStore, settingsStore);
const quickTradeStore = new QuickTradeStore(accountStore);
const whaleSignalsStore = new WhaleSignalsStore(socketStore, ordersStore);

export const storesContext = createContext({
  authStore,
  accountStore,
  socketStore,
  settingsStore,
  swapStore: new SwapStore(),
  ordersStore,
  tokenStore,
  tokenTrade,
  quickTradeStore,
  whaleSignalsStore,
  walletStore,
  blockchainStore,
  researchStore,
  flipStore,
});
