import React from 'react';
import { vibrate } from '@helpers/webApp';
import { ImpactParam } from '../../types/telegram-web-app';

interface VibruttonProps {
  children: React.ReactNode;
  onClick?: () => void;
  vibrate?: ImpactParam;
  className?: string;
  disabled?: boolean;
  protectFromDrag?: boolean
}

const Vibrutton = ({
  children,
  onClick,
  className,
  disabled,
  protectFromDrag,
  vibrate: impact = 'light',
}: VibruttonProps) => {
  const mouseEventHendlerProps = {
    [protectFromDrag ? 'onPointerDown' : 'onClick']: () => {
      vibrate(impact);
      if (onClick) onClick();
    }
  };

  return (
    <button
      className={className}
      disabled={disabled}
      {...mouseEventHendlerProps}
    >
      {children}
    </button>
  );
};

export default Vibrutton;
