import { Blockchain } from 'types/enums';
import { ITradeSettings, NumericType, UserSettings } from 'types';
import { SolPriorityMode } from 'types/user/settings.types';
import { FlipSettings } from 'types/flip/flip-settings.request';

type CommonSettings = Omit<FlipSettings, 'priorityMode'>;

const commonFlipSettings: CommonSettings = {
  antiMev: false,
  buyFee: 0.001,
  sellFee: 0.002,
  buySlippage: 5,
  sellSlippage: 5,
  buySellSettings: {
    buy: ['0.25', '0.5', '1'],
    sell: ['20', '50', '100'],
  },
  panicButton: false,
  activeBuySellSetting: { buy: '1', sell: '50' },
  useWSol: false,
  lyubaMode: false,
  useBloxroute: false,
  solTip: 0.001,
};

export const defaultFlipSettings: FlipSettings[] = [
  {
    ...commonFlipSettings,
    priorityMode: SolPriorityMode.REGULAR,
  },
  {
    ...commonFlipSettings,
    priorityMode: SolPriorityMode.FAST,
  },
  {
    ...commonFlipSettings,
    priorityMode: SolPriorityMode.DEGEN,
  },
  {
    ...commonFlipSettings,
    priorityMode: SolPriorityMode.REGULAR,
    antiMev: true,
  },
  {
    ...commonFlipSettings,
    priorityMode: SolPriorityMode.FAST,
    antiMev: true,
  },
  {
    ...commonFlipSettings,
    priorityMode: SolPriorityMode.DEGEN,
    antiMev: true,
  },
];

export const defaultFlipAmounts: {
  buyAmount: NumericType;
  sellAmount: NumericType;
} = {
  buyAmount: '0.5',
  sellAmount: '100',
};

export const defaultSettings: UserSettings = {
  notifyEnabled: false,
  researchApproved: false,
  avatarOptions: {},
  tradeSettings: [
    {
      blockchain: Blockchain.ETHEREUM,
      amounts: {
        buy: ['10', '50', '100', '1000'],
        sell: ['10', '50', '100', '1000'],
      },
      percents: ['25', '50', '75', '90'],
      triggers: ['25', '50', '200', '300'],
      analyticsBot: 'ttfbotbot',
      chartService: 'dexscreener',
      orderSettings: {
        slippage: 5,
        autoApproveEnabled: true,
        antiMevEnabled: true,
        extraGasPricePercent: 10,
      },
      stopLossSettings: {
        slippage: 5,
        autoApproveEnabled: true,
        antiMevEnabled: true,
        extraGasPricePercent: 10,
      },
      takeProfitSettings: {
        slippage: 5,
        autoApproveEnabled: true,
        antiMevEnabled: true,
        extraGasPricePercent: 10,
      },
    },
    {
      blockchain: Blockchain.BSC,
      amounts: {
        buy: ['10', '50', '100', '1000'],
        sell: ['10', '50', '100', '1000'],
      },
      percents: ['25', '50', '75', '90'],
      triggers: ['25', '50', '200', '300'],
      analyticsBot: 'ttfbotbot',
      chartService: 'dexscreener',
      orderSettings: { slippage: 5, autoApproveEnabled: true },
      stopLossSettings: { slippage: 5, autoApproveEnabled: true },
      takeProfitSettings: { slippage: 5, autoApproveEnabled: true },
    },
    {
      blockchain: Blockchain.BASE,
      amounts: {
        buy: ['10', '50', '100', '1000'],
        sell: ['10', '50', '100', '1000'],
      },
      percents: ['25', '50', '75', '90'],
      triggers: ['25', '50', '200', '300'],
      analyticsBot: 'ttfbotbot',
      chartService: 'dexscreener',
      orderSettings: { slippage: 5, autoApproveEnabled: true },
      stopLossSettings: { slippage: 5, autoApproveEnabled: true },
      takeProfitSettings: { slippage: 5, autoApproveEnabled: true },
    },
    {
      blockchain: Blockchain.SOLANA,
      amounts: {
        buy: ['10', '50', '100', '1000'],
        sell: ['10', '50', '100', '1000'],
      },
      percents: ['25', '50', '75', '90'],
      triggers: ['25', '50', '200', '300'],
      analyticsBot: 'ttfbotbot',
      chartService: 'dexscreener',
      orderSettings: {
        slippage: 5,
        solPriorityMode: SolPriorityMode.FAST,
        antiMevEnabled: true,
      },
      stopLossSettings: {
        slippage: 5,
        solPriorityMode: SolPriorityMode.FAST,
        antiMevEnabled: false,
      },
      takeProfitSettings: {
        slippage: 5,
        solPriorityMode: SolPriorityMode.FAST,
        antiMevEnabled: false,
      },
    },
    {
      blockchain: Blockchain.SUI,
      amounts: {
        buy: ['10', '50', '100', '1000'],
        sell: ['10', '50', '100', '1000'],
      },
      percents: ['25', '50', '75', '90'],
      triggers: ['25', '50', '200', '300'],
      analyticsBot: 'ttfbotbot',
      chartService: 'dexscreener',
      orderSettings: { slippage: 5 },
      stopLossSettings: { slippage: 5 },
      takeProfitSettings: { slippage: 5 },
    },
    {
      blockchain: Blockchain.TON,
      amounts: {
        buy: ['10', '50', '100', '1000'],
        sell: ['10', '50', '100', '1000'],
      },
      percents: ['25', '50', '75', '90'],
      triggers: ['25', '50', '200', '300'],
      analyticsBot: 'ttfbotbot',
      chartService: 'dexscreener',
      orderSettings: { slippage: 5 },
      stopLossSettings: { slippage: 5 },
      takeProfitSettings: { slippage: 5 },
    },
    {
      blockchain: Blockchain.TRON,
      amounts: {
        buy: ['10', '50', '100', '1000'],
        sell: ['10', '50', '100', '1000'],
      },
      percents: ['25', '50', '75', '90'],
      triggers: ['25', '50', '200', '300'],
      analyticsBot: 'ttfbotbot',
      chartService: 'dexscreener',
      orderSettings: { slippage: 5, autoApproveEnabled: true },
      stopLossSettings: { slippage: 5, autoApproveEnabled: true },
      takeProfitSettings: { slippage: 5, autoApproveEnabled: true },
    },
  ],
  pinCodeEnabled: false,
  flipSettings: defaultFlipSettings,
};

export const getDefaultTradeSettings = (blockchain: Blockchain) =>
  defaultSettings.tradeSettings.find(
    (s) => s.blockchain === blockchain,
  ) as unknown as ITradeSettings;
